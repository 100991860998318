import PropTypes from 'prop-types'
import React from 'react'

const icons = {
  jpeg: '/assets/icons/icon-jpg.svg',
  jpg: '/assets/icons/icon-jpg.svg',
  pdf: '/assets/icons/icon-pdf.svg',
  png: '/assets/icons/icon-png.svg',
  svg: '/assets/icons/icon-svg.svg',
  default: '/assets/icons/icon-other.svg',
}

const FileIcon = ({ extension = '' }) => {
  const icon = icons[extension] || icons.default

  return <img src={icon} alt={extension} />
}

FileIcon.defaultProps = {
  extension: 'default',
}

FileIcon.propTypes = {
  extension: PropTypes.string,
}

export default FileIcon
