const paths = {
  index: '/',
  unauthorized: '/401',
  notFound: '/404',
  serverError: '/500',
  letter: '/letter/:key',
  authenticity: 'authenticity',
}

export default paths
