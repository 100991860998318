import React, { useMemo, useState } from 'react'
import { defaultLetterConfirmationParams, LetterConfirmationParamsContext } from './letter-confirmation-params-context'

const LetterConfirmationParamsProvider = ({ children }) => {
  const [letterConfirmationParams, setLetterConfirmationParams] = useState(defaultLetterConfirmationParams)
  const [validationMessage, setValidationMessage] = useState('')

  const saveLetterConfirmationParams = (newParams) => {
    setLetterConfirmationParams(newParams)
  }
  
  const value = useMemo(() => ({
    letterConfirmationParams: letterConfirmationParams,
    saveLetterConfirmationParams: saveLetterConfirmationParams,
    validationMessage: validationMessage,
    setValidationMessage: setValidationMessage,
  }), [letterConfirmationParams, saveLetterConfirmationParams])

  return (
    <LetterConfirmationParamsContext.Provider value={value}>
      {children}
    </LetterConfirmationParamsContext.Provider>
  )
}

export default LetterConfirmationParamsProvider 
