import * as Sentry from '@sentry/browser'
import React from 'react'
import * as ReactDOM from 'react-dom/client'
import { CustomThemeProvider } from './contexts/theme' 

import App from './App'

if (process.env.NODE_ENV === 'production') {
  Sentry.onLoad(() => {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.REACT_APP_ENVIRONMENT,
    })
  })
}
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <CustomThemeProvider>
    <App />
  </CustomThemeProvider>,
)
