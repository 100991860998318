import { Divider, Stack, Typography } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'

const PaymentNotice = ({ theme }) => (
  <Stack justifyContent="space-between" textAlign="center" spacing={2.5}>
    <Divider sx={{ borderBottomWidth: 2 }} />
    <Typography
      variant="subtitle"
      sx={{
        fontWeight: 'bold',
        fontSize: '1.125rem',
        color: theme.palette.primary.main,
      }}
    >
      Comunicado sobre ausência de identificação de pagamento
    </Typography>
    <Divider sx={{ borderBottomWidth: 2 }} />
  </Stack>
)

PaymentNotice.propTypes = {
  theme: PropTypes.object.isRequired,
}


export default PaymentNotice
