import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import PropTypes from 'prop-types'
import React from 'react'

import HeaderSimple from './common/header-simple'

const CompactLayout = ({ children }) => (
  <React.Fragment>
    <HeaderSimple />

    <Container component="main" sx={{ p: 0 }}>
      <Stack
        sx={{
          py: 10,
          px: 0,
          m: 'auto',
          mx: '0',
          minHeight: '100dvh',
          justifyContent: 'center',
        }}
      >
        {children}
      </Stack>
    </Container>
  </React.Fragment>
)

CompactLayout.defaultProps = {
  children: null,
}

CompactLayout.propTypes = {
  children: PropTypes.node,
}

export default CompactLayout
