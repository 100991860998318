import Stack from '@mui/material/Stack'
import useMediaQuery from '@mui/material/useMediaQuery'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

import PropertyList from 'components/property-list'
import PropertyListItem from 'components/property-list-item'
import InsuredSummary from 'sections/letter/insured_summary'
import LetterDateSummary from 'sections/letter/letter_date_summary'
import { defaultLetterConfirmationParams } from 'contexts/letter-confirmation-params'
import useLetterConfirmationParams from 'hooks/use-letter-confirmation-params'
import LetterTypography from 'components/letter-typography'
import ConclusiveOpinionSummary from '../conclusive_opinion_summary'
import RequestSummary from '../request_summary'

const TotalRefusalClinicalImperative = ({ letter }) => {
  const { saveLetterConfirmationParams } = useLetterConfirmationParams()

  const { params } = letter
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const align = mdUp ? 'horizontal' : 'vertical'

  useEffect(() => {
    saveLetterConfirmationParams(defaultLetterConfirmationParams)
  }, [])

  return (
    <Stack
      textAlign="left"
      direction="column"
      justifyContent="space-between"
      spacing={4}
    >
      <LetterDateSummary date={letter.created_at} />
      
      <Stack>
        <InsuredSummary name={params.nome_segurado} code={params.identificacao_segurado} />
        <PropertyList>
          <PropertyListItem
            align={align}
            label="Profissional Solicitante:"
            value={params.nome_profissional_solicitante}
          />

          <PropertyListItem
            align={align}
            label="Profissional desempatador:"
            value={params.nome_desempatador}
          />
        </PropertyList>
      </Stack>

      <LetterTypography>
        Baseado na avaliação de Junta Médica, conforme Resolução Normativa - RN N.º 424, de 26 de Junho de 2017 da
        Agência Nacional de Saúde Suplementar (ANS), e de acordo com a cobertura contratual do plano de saúde o (s)
        procedimento (s) e/ou material (is) solicitados foram recusados, conforme descrito abaixo, no entanto, informamos
        que foi autorizado o <b>imperativo clínico*</b>.
      </LetterTypography>

      <ConclusiveOpinionSummary conclusive_opinion={params.parecer} />

      <RequestSummary
        materials={params.materiais}
        procedures={params.procedimentos}
        displayText={false}
      />

      <LetterTypography>
        Conforme, Resolução Normativa Nº 465, DE 24 DE FEVEREIRO DE 2021 da ANS:
      </LetterTypography>

      <LetterTypography>
        * VIII - <b>Imperativo Clínico</b>: Situação em que um procedimento da segmentação odontológica ou ambulatorial
        requer suporte hospitalar em razão de necessidade ou condição clínica do beneficiário com vistas a diminuir
        eventuais riscos decorrentes da intervenção, conforme declaração do médico ou odontólogo assistente
      </LetterTypography>

      <LetterTypography>
        <b>IMPORTANTE:</b> II - Os honorários do cirurgião-dentista e os materiais odontológicos utilizados na execução
        dos procedimentos odontológicos ambulatoriais que nas situações de imperativo clínico necessitem ser realizados
        em ambiente hospitalar não estão incluídos na cobertura da segmentação hospitalar e plano referência
      </LetterTypography>

      <LetterTypography>
        <b>Esta solicitação poderá ser submetida à análise técnica de seu plano odontológico, caso possua.</b>
      </LetterTypography>

      <Stack
        textAlign="left"
        direction="column"
        justifyContent="space-between"
        spacing={2}
      >
        <LetterTypography fontStyle="italic">
          Ressaltamos que esta comunicação se limita aos procedimentos e/ou materiais avaliados em Junta Médica, por
          favor verifique com o prestador/hospital ou no seu APP SulAmérica se houve alguma restrição contratual.
        </LetterTypography>

        <LetterTypography fontStyle="italic">
          Para mais informações, entre em contato com a nossa Central de
          Atendimento 24 Horas através do telefone que consta no verso do seu
          cartão de identificação.
        </LetterTypography>

        <LetterTypography>
          Estamos à sua disposição.
          <br />
          Atenciosamente,
          <br />
          SulAmérica
        </LetterTypography>
      </Stack>
    </Stack>
  )
}

TotalRefusalClinicalImperative.propTypes = {
  letter: PropTypes.shape({
    ttl: PropTypes.number.isRequired,
    expires_at: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    template_type: PropTypes.string.isRequired,
    awareness_declared_at: PropTypes.string,
    created_at: PropTypes.string.isRequired,
    params: PropTypes.shape({
      params_name: PropTypes.string,
      nome_segurado: PropTypes.string,
      identificacao_segurado: PropTypes.string,
      nome_profissional_solicitante: PropTypes.string,
      nome_desempatador: PropTypes.string,
      parecer: PropTypes.string,
      materiais: PropTypes.arrayOf(
        PropTypes.shape({
          descricao: PropTypes.string,
          quantidade_solicitada: PropTypes.number,
          quantidade_autorizada: PropTypes.number,
          justificativa: PropTypes.string,
        }),
      ),
      procedimentos: PropTypes.arrayOf(
        PropTypes.shape({
          descricao: PropTypes.string,
          quantidade_solicitada: PropTypes.number,
          codigo_procedimento: PropTypes.string,
          quantidade_autorizada: PropTypes.number,
          justificativa: PropTypes.string,
        }),
      ),
    }).isRequired,
  }).isRequired,
}

export default TotalRefusalClinicalImperative
