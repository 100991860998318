/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import React from 'react'
import { Close } from '@mui/icons-material'
import { SvgIcon } from '@mui/material'
import FileIcon from './file-icon'
import { bytesToSize } from '../utils/file-utils'

export const FileDropzone = (props) => {
  const {
    caption,
    files = [],
    onRemove,
    onRemoveAll,
    onUpload,
    ...other
  } = props

  const {
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone(other)

  const hasAnyFiles = files.length > 0
  const hasMaxFiles = files.length >= other.maxFiles


  return (
    <div>
      {!hasMaxFiles && (
        <Box
          sx={{
            alignItems: 'center',
            border: 1,
            borderRadius: 1,
            borderStyle: 'dashed',
            borderColor: 'divider',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            outline: 'none',
            backgroundColor: 'action.hover',
            opacity: 0.8,
            p: 6,
            ...(isDragActive && {
              backgroundColor: 'action.active',
              opacity: 0.5,
            }),
            '&:hover': {
              backgroundColor: 'action.hover',
              cursor: 'pointer',
              opacity: 0.5,
            },
          }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />

          <Stack
            alignItems="center"
            textAlign="center"
            direction="column"
            spacing={3}
          >
            <Box
              alt="add files"
              component="img"
              src="assets/add_files.svg"
              sx={{
                height: 82,
                width: 82,
              }}
            />

            <Stack spacing={1}>
              <Typography
                sx={{
                  '& span': {
                    textDecoration: 'underline',
                  },
                }}
                variant="h6"
              >
                Solte ou selecione um arquivo
              </Typography>
              {caption && (
                <Typography
                  color="text.secondary"
                  variant="body2"
                >
                  {caption}
                </Typography>
              )}
            </Stack>
          </Stack>
        </Box>
      )}
      {
        hasAnyFiles && (
          <Box sx={{ mt: 2 }}>
            <List>
              {files.map((file) => {
                const extension = file.name.split('.').pop()

                return (
                  <ListItem
                    key={file.path}
                    sx={{
                      border: 1,
                      borderColor: 'divider',
                      borderRadius: 1,
                      '& + &': {
                        mt: 1,
                      },
                    }}
                  >
                    <ListItemIcon>
                      <FileIcon extension={extension} />
                    </ListItemIcon>
                    <ListItemText
                      primary={file.name}
                      primaryTypographyProps={{ variant: 'subtitle2' }}
                      secondary={bytesToSize(file.size)}
                    />
                    <Tooltip title="Remover">
                      <IconButton
                        edge="end"
                        onClick={() => onRemove?.(file)}
                      >
                        <SvgIcon>
                          <Close />
                        </SvgIcon>
                      </IconButton>
                    </Tooltip>
                  </ListItem>
                )
              })}
            </List>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="flex-end"
              spacing={2}
              sx={{ mt: 2 }}
            >
              <Button
                color="inherit"
                onClick={onRemoveAll}
                size="small"
                type="button"
              >
                Limpar arquivos
              </Button>

              <Button
                onClick={onUpload}
                size="small"
                type="button"
                variant="contained"
              >
                Enviar
              </Button>
            </Stack>
          </Box>
        )
      }
    </div>
  )
}


FileDropzone.propTypes = {
  caption: PropTypes.string,
  files: PropTypes.array,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  onUpload: PropTypes.func,
  accept: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string.isRequired).isRequired),
  disabled: PropTypes.bool,
  getFilesFromEvent: PropTypes.func,
  maxFiles: PropTypes.number,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  noClick: PropTypes.bool,
  noDrag: PropTypes.bool,
  noDragEventsBubbling: PropTypes.bool,
  noKeyboard: PropTypes.bool,
  onDrop: PropTypes.func,
  onDropAccepted: PropTypes.func,
  onDropRejected: PropTypes.func,
  onFileDialogCancel: PropTypes.func,
  preventDropOnDocument: PropTypes.bool,
}
