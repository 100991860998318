import React from 'react'
import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import PropTypes from 'prop-types'
import ContactCard from '../components/contact_card'

const SulaPortalCard = ({ websites }) => {
  const theme = useTheme()
  return (
    <ContactCard
      image={{
        src: 'https://www.sulamerica.com.br/emkt/saude/3399-icone-laptop.png',
        alt: 'App SulAmérica Saúde',
      }}
    >
      <Typography variant="subtitle1" color="textPrimary">
        Site SulAmérica {' '}
        {websites.map((website) => (
          <a
            key={website.url}
            href={website.url}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: theme.palette.text.primary,
            }}
          >
            <span style={{ fontWeight: 'bold' }}>{website.display_text}</span>
          </a>
        ))}
      </Typography>
    </ContactCard>
  )
}

SulaPortalCard.propTypes = {
  websites: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      display_text: PropTypes.string.isRequired,
      additional_info: PropTypes.string,
    }),
  ).isRequired,
}

export default SulaPortalCard
