import Typography from '@mui/material/Typography'
import React from 'react'
import PropTypes from 'prop-types'
import Stack from '@mui/material/Stack'
import { format, parseISO } from 'date-fns'
import LetterTypography from 'components/letter-typography'


const LetterDateSummary = (props) => {
  const { date } = props

  return (
    <Stack
      textAlign="left"
      direction="column"
      justifyContent="space-between"
      spacing={1}
    >
      <Typography color="text.primary" variant="subtitle1">
        {date ? format(parseISO(date), 'dd/MM/yyyy') : '-'}
      </Typography>

      <LetterTypography>
        Prezados(as),
      </LetterTypography>
    </Stack>
  )
}

LetterDateSummary.defaultProps = {
  date: null,
}

LetterDateSummary.propTypes = {
  date: PropTypes.string,
}

export default LetterDateSummary
