import { useContext } from 'react'
import { CustomThemeContext } from '../contexts/theme'

/**
 * Custom hook to access the application's theme context.
 *
 * @returns {object} - Returns the context object, which includes the current theme and the function to change it.
 * @throws {Error} - Throws an error if the hook is used outside the ThemeProvider.
 */
const useCustomTheme = () => useContext(CustomThemeContext)

export default useCustomTheme
