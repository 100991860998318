import React from 'react'
import { Link } from 'react-router-dom'
import MaterialLink from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import { REACT_APP_LETTER_VIDEO_HYPERLINK } from '../../config'


const VideoHyperlink = () => (
  <Stack
    style={{ maxWidth: '100%' }}
    align="center"
    justifyContent="center"
    sx={{
      flexGrow: 1,
      minHeight: 1,
    }}
  >
    <Link
      to={REACT_APP_LETTER_VIDEO_HYPERLINK}
      target="_blank"
    >
      <img
        src="https://static-images-inovamind.s3.amazonaws.com/sa/vpp/assistir_video_junta_medica.png"
        alt="Assistir vídeo"
        style={{
          maxWidth: '100%',
          height: 'auto',
        }}
      />
    </Link>
    <br />
    <MaterialLink
      sx={{
        display: 'inline-block',
        color: 'text.primary',
        textDecoration: 'underline',
      }}
      target='_blank'
      component={Link}
      to={REACT_APP_LETTER_VIDEO_HYPERLINK}>{REACT_APP_LETTER_VIDEO_HYPERLINK}</MaterialLink>
  </Stack>
)

export default VideoHyperlink
