import { alpha } from '@mui/system/colorManipulator'

const withAlphas = (color) => ({
  ...color,
  alpha4: alpha(color.main, 0.04),
  alpha8: alpha(color.main, 0.08),
  alpha12: alpha(color.main, 0.12),
  alpha30: alpha(color.main, 0.3),
  alpha50: alpha(color.main, 0.5),
})

export const neutral = {
  50: '#E0E0E1',
  100: '#B3B4B5',
  200: '#808182',
  300: '#4D4E50',
  400: '#373839',
  500: '#212123',
  600: '#1A1B1C',
  700: '#121314',
  800: '#0A0B0C',
  900: '#020303',
}

export const sulaNeutral = {
  50: '#e6e8f3',
  100: '#c3c9e9',
  200: '#99a3d3',
  300: '#7385c0',
  400: '#5472af',
  500: '#3d5a9e',
  600: '#344a8c',
  700: '#2a3879',
  800: '#001e64',
  900: '#001e64',
}

export const prcNeutral = {
  50: '#F5F5F5',
  100: '#E0E0E0',
  200: '#BDBDBD',
  300: '#9E9E9E',
  400: '#757575',
  500: '#616161',
  600: '#424242',
  700: '#303030',
  800: '#808080',
  900: '#808080',
}

export const main = withAlphas({
  lightest: '#FFF5E6',
  light: '#FFB84D',
  main: '#FF5200',
  dark: '#E64E00',
  darkest: '#B74200',
  contrastText: '#FFFFFF',
})

export const prcMain = withAlphas({
  lightest: '#FDEDEC',
  light: '#E74C3C',
  main: '#AC0D17',
  dark: '#8E0A14',
  darkest: '#700812',
  contrastText: '#FFFFFF',
})

export const success = withAlphas({
  lightest: '#D3FCD2',
  light: '#77ED8B',
  main: '#22C55E',
  dark: '#118D57',
  darkest: '#065E49',
  contrastText: '#FFFFFF',
})

export const info = withAlphas({
  lightest: '#CAFDF5',
  light: '#61F3F3',
  main: '#00B8D9',
  dark: '#006C9C',
  darkest: '#003768',
  contrastText: '#FFFFFF',
})

export const warning = withAlphas({
  lightest: '#FFFAEB',
  light: '#FEF0C7',
  main: '#F79009',
  dark: '#B54708',
  darkest: '#7A2E0E',
  contrastText: '#FFFFFF',
})

export const error = withAlphas({
  lightest: '#FFE9D5',
  light: '#FFAC82',
  main: '#FF5630',
  dark: '#B71D18',
  darkest: '#7A0916',
  contrastText: '#FFFFFF',
})

export const themeConfigs = {
  prc: {
    palette: {
      main: prcMain,
      neutral: prcNeutral,
      error,
      info,
      success,
      warning,
    },
  },
  sula: {
    palette: {
      main: main,
      neutral: sulaNeutral,
      error,
      info,
      success,
      warning,
    },
  },
  default: {
    palette: {
      main: main,
      neutral: neutral,
      error,
      info,
      success,
      warning,
    },
  },
}
