import React from 'react'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'
import { CircularProgress } from '@mui/material'
import { confirmLetter } from 'integrations/api'
import useLetterConfirmationParams from 'hooks/use-letter-confirmation-params'
import { useNavigate } from 'react-router-dom'
import { useConfirm } from 'material-ui-confirm'
import paths from '../../paths'
import { ERROR_MESSAGES } from '../../constants'


const notifyResult = async (setLoading, confirm, title, message) => {
  setLoading(false)

  await confirm({
    title,
    description: message,
    hideCancelButton: true,
  })
}

const handleErrors = async (error, navigate, confirm, setLoading) => {
  if (!error.response || !error.response.status) {
    navigate(paths.serverError)
    return
  }

  switch (error.response.status) {
    case 500:
      navigate(paths.serverError)
      break
    case 422:
      await notifyResult(setLoading, confirm, 'Erro', ERROR_MESSAGES.INTERNAL_SERVER_ERROR)
      break
    default:
      await notifyResult(setLoading, confirm, 'Erro', error.response.data.detail)
  }
}

const LetterConfirmationForm = (
  {
    letter_id,
    loading,
    setLoading,
    setLetter,
  },
) => {
  const navigate = useNavigate()
  const { letterConfirmationParams, validationMessage } = useLetterConfirmationParams()

  const confirm = useConfirm()

  const handleConfirmation = async () => {
    if (loading)
      return
    
    if (validationMessage) {
      await confirm({
        title: 'Erro',
        description: validationMessage,
        hideCancelButton: true,
      })
      return
    }

    setLoading(true)

    try {
      const response = await confirmLetter(letter_id, letterConfirmationParams)
      const { data } = response
      setLetter(data)
      setLoading(false)
      
      await confirm({
        title: 'Sucesso',
        description: 'Envio realizado!',
        hideCancelButton: true,
      })
    } catch (e) {
      await handleErrors(e, navigate, confirm, setLoading)
    } finally {
      setLoading(false)
    }
  }

  const handleClick = async () => {
    await handleConfirmation(letterConfirmationParams)
  }

  return (
    <Grid container>
      <Grid md={2} xs={12} item>
        <Button
          fullWidth={true}
          disabled={loading}
          type="submit"
          variant="contained"
          onClick={handleClick}
          startIcon={
            loading ? <CircularProgress size={20} /> : null
          }
        >
          Confirmar
        </Button>
      </Grid>
    </Grid>
  )
}

LetterConfirmationForm.propTypes = {
  letter_id: PropTypes.string.isRequired,
}


export default LetterConfirmationForm
