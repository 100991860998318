import useMediaQuery from '@mui/material/useMediaQuery'
import PropTypes from 'prop-types'
import React from 'react'

import PropertyListItem from 'components/property-list-item'

const InsuredSummary = (props) => {
  const {
    name,
    code,
  } = props
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const align = mdUp ? 'horizontal' : 'vertical'

  return (
    <React.Fragment>
      <PropertyListItem align={align} label="Segurado(a):" value={name || '-'} />

      <PropertyListItem
        align={align}
        label="Cód. de Identificação:"
        value={code || '-'}
      />
    </React.Fragment>
  )
}

InsuredSummary.defaultProps = {
  name: null,
  code: null,
}

InsuredSummary.propTypes = {
  name: PropTypes.string,
  code: PropTypes.string,
}

export default InsuredSummary
