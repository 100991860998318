import React from 'react'
import { Typography } from '@mui/material'
import ContactCard from '../components/contact_card'

const SulaAppCard = () => (
  <ContactCard
    image={{
      src: 'https://www.sulamerica.com.br/emkt/saude/3399-icone-celular.png',
      alt: 'App SulAmérica Saúde',
    }}
  >
    <Typography variant="subtitle1" color="textPrimary">
      App <span style={{ fontWeight: 'bold' }}>SulAmérica Saúde</span>
    </Typography>
  </ContactCard>
)

export default SulaAppCard
