import createComponents from './create-components'
import createPalette from './create-palette'
import createShadows from './create-shadows'

const createOptions = (config) => {
  const palette = createPalette(config)
  const components = createComponents({ palette })
  const shadows = createShadows()

  return {
    components,
    palette,
    shadows,
  }
}

export default createOptions
