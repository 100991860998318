import {
  createTheme as createMuiTheme,
  responsiveFontSizes,
} from '@mui/material/styles'

import createBaseOptions from './base/create-options'
import createLightOptions from './light/create-options'

const createTheme = (config) => {
  let theme = createMuiTheme(
    createBaseOptions(),
    createLightOptions(config),
  )

  theme = responsiveFontSizes(theme)

  return theme
}

export default createTheme
