import { styled } from '@mui/system'
import { Box, Stack, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React from 'react'
import PropTypes from 'prop-types'

const ImageBox = styled(Box)(({ theme }) => ({
  width: 36,
  height: 32,
  flexShrink: 0,
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    borderRadius: theme.shape.borderRadius,
  },
}))
const InstructionSection = ({ title, content, image, color }) => {
  const theme = useTheme()

  return (
    <Stack
      spacing={1.5}
      sx={{
        textAlign: 'center',
        color: color,
        borderRadius: '8px',
        padding: {
          md: 2,
          xs: 0,
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ImageBox>
          <img src={image.src} alt={image.alt} />
        </ImageBox>
        <Typography
          variant="h6"
          component="span"
          sx={{
            fontWeight: 'bold',
            marginLeft: '8px',
            color: color,
          }}
        >
          {title}
        </Typography>
      </Box>
      <Typography
        variant="body1"
        sx={{
          color: theme.palette.text.primary,
          fontWeight: '400',
          marginBottom: '8px',
          width: '100%',
          maxWidth: '100%',
          boxSizing: 'border-box',
        }}
      >
        {content}
      </Typography>
    </Stack>
  )
}

InstructionSection.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  color: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }).isRequired,
}

export default InstructionSection
