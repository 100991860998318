import { CacheProvider } from '@emotion/react'
import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/system'
import React from 'react'

import Toaster from 'components/toaster'
import createEmotionCache from 'utils/create_emotion_cache'
import { ConfirmProvider } from 'material-ui-confirm'

import 'index.css'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useCustomTheme } from 'hooks'
import Router from './router'

const clientSideEmotionCache = createEmotionCache()

const App = ({ emotionCache = clientSideEmotionCache }) => {
  const { theme } = useCustomTheme()

  return (
      <CacheProvider value={emotionCache}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ThemeProvider theme={theme}>
            <ConfirmProvider
              defaultOptions={{
                titleProps: {
                  variant: 'caption',
                  fontSize: '1.4rem',
                },
                confirmationButtonProps: { autoFocus: true },
                contentProps: {
                  variant: 'body2',
                  fontSize: '0.8rem',
                  color: 'text.secondary',
                },
              }}
            >
              <CssBaseline />
              <Toaster />
              <Router />
            </ConfirmProvider>
          </ThemeProvider>
        </LocalizationProvider>
      </CacheProvider>
  )
}

export default App
