import createComponents from './create-components'
import createTypography from './create-typography'

const createOptions = () => {
  const direction = 'ltr'

  return {
    breakpoints: {
      values: {
        xs: 0,
        sm: 640,
        md: 900,
        lg: 1200,
        xl: 1440,
      },
    },
    components: createComponents(),
    direction,
    shape: {
      borderRadius: 8,
    },
    typography: createTypography(),
  }
}

export default createOptions
