import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import PropTypes from 'prop-types'
import React from 'react'

import ServerError from 'components/server-error'
import useTitle from 'hooks/use-title'
import CompactLayout from 'layouts/compact-layout'

const ErrorPage = ({
  seoTitle,
  imgSrc,
  imgAlt,
  title,
  description,
  redirectText,
  redirectTo,
}) => {
  useTitle(seoTitle)
  return (
    <CompactLayout>
      <Container maxWidth="lg">
        <Box
          component="main"
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'center',
            overflow: 'hidden',
          }}
        >
          <ServerError
            imgSrc={imgSrc}
            imgAlt={imgAlt}
            title={title}
            description={description}
            redirectTo={redirectTo}
            redirectText={redirectText}
          />
        </Box>
      </Container>
    </CompactLayout>
  )
}

ErrorPage.defaultProps = {
  redirectText: null,
  redirectTo: null,
}

ErrorPage.propTypes = {
  seoTitle: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  redirectText: PropTypes.string,
  redirectTo: PropTypes.string,
}
export default ErrorPage
