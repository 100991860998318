import { common } from '@mui/material/colors'
import { alpha } from '@mui/system/colorManipulator'

import { themeConfigs } from '../colors'

const createPalette = (config) => {
  const { theme_name } = config
  const {
    main,
    neutral,
    error,
    info, 
    success,
    warning,
  } = themeConfigs[theme_name].palette
  
  const disabledColor = alpha(neutral[900], 0.38)
  const focusColor = alpha(neutral[900], 0.16)

  const selectedColor = alpha(neutral[900], 0.12)
  const hoverColor = alpha(neutral[900], 0.04)

  return {
    action: {
      active: neutral[500],
      disabled: disabledColor,
      disabledBackground: selectedColor,
      focus: focusColor,
      hover: hoverColor,
      selected: selectedColor,
    },
    background: {
      default: common.white,
      paper: common.white,
    },
    divider: '#F2F4F7',
    error,
    info,
    mode: 'light',
    neutral,
    primary: main,
    success,
    text: {
      primary: neutral[900],
      secondary: neutral[500],
      disabled: disabledColor,
    },
    warning,
  }
}

export default createPalette
