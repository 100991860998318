import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { format, parseISO } from 'date-fns'
import { currencyFormatter } from '../../../../../utils/currentcy_utils'

const BillingTable = ({ billingItems, type = 'installment' }) => {
  const theme = useTheme()

  const installmentLabel = type === 'installment' ? 'Parcela nº' : 'Fatura nº'

  const headers = [
    installmentLabel,
    'Vencimento',
    'Dias de atraso',
    'Valor originário (R$)',
    'Valor atualizado (R$)*',
  ]

  const headerStyle = {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
    fontSize: '0.2rem !important',
    padding: '0.5rem',
  }

  const cellStyle = {
    fontWeight: 'bold',
    fontSize: '0.8rem',
  }

  return (
    <TableContainer component={Paper}>
      <Table align="center">
        <TableHead>
          <TableRow style={{ alignContent: 'center' }}>
            {headers.map((header) => (
              <TableCell align="center" key={header} style={headerStyle}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {billingItems.map((row) => (
            <TableRow
              key={row.number}
              style={{
                backgroundColor: theme.palette.background.default,
                textAlign: 'center',
              }}
            >
              <TableCell align="center" sx={cellStyle}>
                {row.number}
              </TableCell>
              <TableCell align="center" sx={cellStyle}>
                {format(parseISO(row.due_date), 'dd/MM/yyyy')}
              </TableCell>
              <TableCell align="center" sx={cellStyle}>
                {row.days_late}
              </TableCell>
              <TableCell align="center" sx={cellStyle}>
                {currencyFormatter.format(row.original_value)}
              </TableCell>
              <TableCell align="center" sx={cellStyle}>
                {currencyFormatter.format(row.updated_value)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

BillingTable.propTypes = {
  billingItems: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.string.isRequired,
      due_date: PropTypes.string.isRequired,
      days_late: PropTypes.number.isRequired,
      original_value: PropTypes.number.isRequired,
      updated_value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  type: PropTypes.oneOf(['installment', 'invoice']),
}

BillingTable.defaultProps = {
  type: 'installment',
}

export default BillingTable
