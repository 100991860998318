import React from 'react'

import ErrorPage from './error-page'

const Page = () => (
  <ErrorPage
    seoTitle="Em Construção"
    imgSrc="/assets/under-construction.svg"
    imgAlt="Página em construção"
    title="Página em construção"
    description="Desculpe o inconveniente. Estamos trabalhando para melhorar sua experiência. Por favor, volte mais tarde."
  />
)

export default Page
