import PropTypes from 'prop-types'
import React from 'react'

import Typography from '@mui/material/Typography'
import PropertyList from '../../components/property-list'
import LetterTypography from '../../components/letter-typography'

const ConclusiveOpinionSummary = (props) => {
  const {
    conclusive_opinion,
  } = props

  return (
    <PropertyList>
      <Typography color="text.primary" variant="subtitle1" mb={.5}>
        Parecer Conclusivo da Junta Médica (Embasamento Técnico):
      </Typography>

      <LetterTypography>
        {conclusive_opinion || '-'}
      </LetterTypography>
    </PropertyList>
  )
}

ConclusiveOpinionSummary.defaultProps = {
  conclusive_opinion: null,
}

ConclusiveOpinionSummary.propTypes = {
  conclusive_opinion: PropTypes.string,
}

export default ConclusiveOpinionSummary
