import React from 'react'
import { Divider, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import useMediaQuery from '@mui/material/useMediaQuery'
import PropTypes from 'prop-types'
import ContactCard from '../components/contact_card'

const WhatsAppCard = ({
  imgSrc = 'https://www.sulamerica.com.br/emkt/saude/3399-whatsapp.png',
  title,
  phone,
}) => {
  const theme = useTheme()
  const mdUp = useMediaQuery((th) => th.breakpoints.up('md'))

  const formattedPhone = phone.replace(/\D/g, '')
  const whatsappLink = `https://wa.me/${formattedPhone}`

  return (
    <ContactCard
      image={{
        src: imgSrc,
        alt: 'Whatsapp',
      }}
    >
      <Stack
        direction={mdUp ? 'row' : 'column'}
        spacing={2.2}
        justifyContent="center"
        alignItems="center"
      >
        <Stack spacing={0} justifyContent="center">
          <Typography variant="subtitle1" color="textPrimary" fontWeight="bold">
            {title}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            Adicione nosso número em seus contatos
          </Typography>
        </Stack>

        <Divider
          orientation={mdUp ? 'vertical' : 'horizontal'}
          flexItem
          sx={{
            borderColor: theme.palette.text.secondary,
            height: mdUp ? 'auto' : '1px',
            width: mdUp ? '1px' : 'auto',
            my: mdUp ? 0 : 1,
          }}
        />
        <a
          href={whatsappLink}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <Typography variant="body1" color="textPrimary" fontSize="1.2rem">
            {phone}
          </Typography>
        </a>
      </Stack>
    </ContactCard>
  )
}

WhatsAppCard.prototypes = {
  title: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
}

export default WhatsAppCard
