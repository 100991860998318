import React from 'react'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import { alpha } from '@mui/system/colorManipulator'
import Stack from '@mui/material/Stack'
import useMediaQuery from '@mui/material/useMediaQuery'
import { styled } from '@mui/system'

const ImageBox = styled(Box)(({ theme }) => ({
  width: 42,
  height: 32,
  flexShrink: 0,
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    borderRadius: theme.shape.borderRadius,
  },
}))
const ContactCard = ({ image, children }) => {
  const mdUp = useMediaQuery((th) => th.breakpoints.up('md'))

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: (theme) => alpha(theme.palette.grey[500], 0.1),
        borderRadius: 5,
        paddingY: 2,
        paddingX: 3,
        marginY: 1,
        gap: 2,
      }}
    >
      <Stack
        direction={mdUp ? 'row' : 'column'}
        spacing={2.2}
        alignItems="center"
        justifyContent={mdUp ? 'left' : 'center'}
        textAlign={mdUp ? 'left' : 'center'}
        sx={{
          width: '100%',
          maxWidth: '100%',
          boxSizing: 'border-box',
        }}
      >
        <ImageBox>
          <img 
            src={image.src} 
            alt={image.alt}
          
          />
        </ImageBox>
        <Stack> {children}</Stack>
      </Stack>
    </Box>
  )
}

ContactCard.propTypes = {
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
}

export default ContactCard
