import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import HomePage from 'pages'
import Unauthorized from 'pages/unauthorized'
import ServerError from 'pages/server-error'
import Letter from 'pages/letter'
import PageNotFound from 'pages/not-found'
import paths from 'paths'
import ReporteIntegrityCheck from 'pages/report-integrity-check'
import ScrollToTop from '../components/scroll-to-top'


const Router = () => (
  <BrowserRouter>
    <ScrollToTop />
    <Routes>
      <Route exact path={paths.index} element={<HomePage />} />
      <Route path={paths.unauthorized} element={<Unauthorized />} />
      <Route path={paths.serverError} element={<ServerError />} />
      <Route path={paths.letter} element={<Letter />} />
      <Route path={paths.authenticity} element={<ReporteIntegrityCheck />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  </BrowserRouter>
)

export default Router
