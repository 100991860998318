import Stack from '@mui/material/Stack'
import useMediaQuery from '@mui/material/useMediaQuery'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

import PropertyList from 'components/property-list'
import PropertyListItem from 'components/property-list-item'
import InsuredSummary from 'sections/letter/insured_summary'
import LetterDateSummary from 'sections/letter/letter_date_summary'
import { defaultLetterConfirmationParams } from 'contexts/letter-confirmation-params'
import useLetterConfirmationParams from 'hooks/use-letter-confirmation-params'
import LetterTypography from 'components/letter-typography'
import ConclusiveOpinionSummary from '../conclusive_opinion_summary'
import RequestSummary from '../request_summary'

const ReimbursementPartialValidationExams = ({ letter }) => {
  const { saveLetterConfirmationParams } = useLetterConfirmationParams()
  const { params } = letter

  const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const align = mdUp ? 'horizontal' : 'vertical'

  useEffect(() => {
    saveLetterConfirmationParams(defaultLetterConfirmationParams)
  }, [])

  return (
    <Stack
      textAlign="left"
      direction="column"
      justifyContent="space-between"
      spacing={4}
    >
      <LetterDateSummary date={letter.created_at} />
      
      <Stack>
        <InsuredSummary name={params.nome_segurado} code={params.identificacao_segurado} />
        <PropertyList>
          <PropertyListItem
            align={align}
            label="Profissional Solicitante:"
            value={params.nome_profissional_solicitante}
          />

          <PropertyListItem
            align={align}
            label="Profissional desempatador:"
            value={params.nome_desempatador}
          />
        </PropertyList>
      </Stack>

      <LetterTypography>
        Recebemos o pedido de reembolso referente ao (s) exame (s), e informamos que sua solicitação foi submetida à
        Junta Médica, conforme disposto na Resolução Normativa - RN N.º 424, de 26 de Junho de 2017 da Agência
        Nacional de Saúde Suplementar (ANS).
      </LetterTypography>

      <LetterTypography>
        Baseado na avaliação de Junta Médica o (s) exame (s) solicitado foram autorizados parcialmente, conforme
        descrito abaixo:
      </LetterTypography>

      <ConclusiveOpinionSummary conclusive_opinion={params.parecer} />

      <RequestSummary
        materials={params.materiais}
        procedures={params.procedimentos}
        displayText={false}
      />

      <Stack
        textAlign="left"
        direction="column"
        justifyContent="space-between"
        spacing={2}
      >
        <LetterTypography
          fontStyle="italic"
        >
          Ressaltamos que esta comunicação se limita aos procedimentos e/ou materiais avaliados em Junta Médica, por
          favor verifique com o prestador/hospital ou no seu APP SulAmérica se houve alguma restrição contratual.
          <br />
          <br />
          Para mais informações, entre em contato com a nossa Central de Atendimento 24 Horas através do telefone que
          consta no verso do seu cartão de identificação.

        </LetterTypography>

        <LetterTypography>
          Estamos à sua disposição.
          <br />
          Atenciosamente,
          <br />
          SulAmérica
        </LetterTypography>
      </Stack>
    </Stack>
  )
}

ReimbursementPartialValidationExams.propTypes = {
  letter: PropTypes.shape({
    ttl: PropTypes.number.isRequired,
    expires_at: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    template_type: PropTypes.string.isRequired,
    awareness_declared_at: PropTypes.string,
    created_at: PropTypes.string.isRequired,
    params: PropTypes.shape({
      params_name: PropTypes.string,
      nome_segurado: PropTypes.string,
      identificacao_segurado: PropTypes.string,
      nome_profissional_solicitante: PropTypes.string,
      nome_desempatador: PropTypes.string,
      parecer: PropTypes.string,
      materiais: PropTypes.arrayOf(
        PropTypes.shape({
          descricao: PropTypes.string,
          quantidade_solicitada: PropTypes.number,
          quantidade_autorizada: PropTypes.number,
          justificativa: PropTypes.string,
        }),
      ),
      procedimentos: PropTypes.arrayOf(
        PropTypes.shape({
          descricao: PropTypes.string,
          quantidade_solicitada: PropTypes.number,
          codigo_procedimento: PropTypes.string,
          quantidade_autorizada: PropTypes.number,
          justificativa: PropTypes.string,
        }),
      ),
    }).isRequired,
  }).isRequired,
}

export default ReimbursementPartialValidationExams
