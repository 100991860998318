import React from 'react'
import LetterTypography from '../../components/letter-typography'
import ProcedureListSummary from './procedure_list_summary'
import MaterialListSummary from './material_list_summary'

const RequestSummary = (
  {
    procedures,
    materials,
    displayText = false,
  },
) => (
  <React.Fragment>
    <ProcedureListSummary procedures={procedures} />
    {displayText && <LetterTypography>
      e/ou materiais:
    </LetterTypography>
    }
    <MaterialListSummary materials={materials} />
  </React.Fragment>
)

export default RequestSummary
