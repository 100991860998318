import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import React, { useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import SplashScreen from 'components/splash_screen'
import useTitle from 'hooks/use-title'
import { getLetter } from 'integrations/api'
import CompactLayout from 'layouts/compact-layout'

import LetterConfirmationParamsProvider from 'contexts/letter-confirmation-params/letter-confirmation-params-provider'
import {
  ConsensusToInsured,
  DeniedConsensusProposalToApplicant,
  HealthLastNoticeIndividualRN593,
  HealthMeiCeiCapefRN593,
  OdontoLastNoticeIndividualRN593,
  OdontoLastNoticeLegalEntityRN593,
  PartialAuthorizationConsensusProposalToApplicant,
  PartialValidation,
  PartialValidationClinicalImperative,
  PartialValidationNonAttendanceDeadlock,
  PartialValidationNonAttendanceExams,
  PRCLastNoticeRN593,
  ReimbursementPartialValidationExams,
  ReimbursementPartialValidationProcedures,
  ReimbursementTotalRefusalExams,
  ReimbursementTotalRefusalProcedures,
  TotalRefusal,
  TotalRefusalClinicalImperative,
  TotalRefusalDentalExtraction,
  TotalRefusalNonAttendanceDeadlock,
  TotalRefusalNonAttendanceExams,
  TotalValidation,
} from 'sections/letter/params'
import ServerError from 'components/server-error'
import { useCustomTheme } from 'hooks'
import { LETTER_TEMPLATE_TYPE } from '../constants'
import paths from '../paths'

import themes from '../theme/light/themes'

const letterTemplateMapping = {
  [LETTER_TEMPLATE_TYPE.CONSENSUS_TO_INSURED]: {
    COMPONENT: ConsensusToInsured,
    title: 'Proposta de Consenso',
  },
  [LETTER_TEMPLATE_TYPE.DENIED_CONSENSUS_PROPOSAL_TO_APPLICANT]: {
    COMPONENT: DeniedConsensusProposalToApplicant,
    title: 'Proposta de Consenso',
  },
  [LETTER_TEMPLATE_TYPE.PARTIAL_AUTHORIZATION_CONSENSUS_PROPOSAL_TO_APPLICANT]:
    {
      COMPONENT: PartialAuthorizationConsensusProposalToApplicant,
      title: 'Proposta de Consenso',
    },
  [LETTER_TEMPLATE_TYPE.TOTAL_VALIDATION]: {
    COMPONENT: TotalValidation,
    title: 'Decisão da Junta Médica',
  },
  [LETTER_TEMPLATE_TYPE.TOTAL_REFUSAL_NON_ATTENDANCE_DEADLOCK]: {
    COMPONENT: TotalRefusalNonAttendanceDeadlock,
    title: 'Decisão da Junta Médica',
  },
  [LETTER_TEMPLATE_TYPE.TOTAL_REFUSAL_NON_ATTENDANCE_EXAMS]: {
    COMPONENT: TotalRefusalNonAttendanceExams,
    title: 'Decisão da Junta Médica',
  },
  [LETTER_TEMPLATE_TYPE.PARTIAL_VALIDATION_NON_ATTENDANCE_DEADLOCK]: {
    COMPONENT: PartialValidationNonAttendanceDeadlock,
    title: 'Decisão da Junta Médica',
  },
  [LETTER_TEMPLATE_TYPE.PARTIAL_VALIDATION_NON_ATTENDANCE_EXAMS]: {
    COMPONENT: PartialValidationNonAttendanceExams,
    title: 'Decisão da Junta Médica',
  },
  [LETTER_TEMPLATE_TYPE.TOTAL_REFUSAL_DENTAL_EXTRACTION]: {
    COMPONENT: TotalRefusalDentalExtraction,
    title: 'Decisão da Junta Médica',
  },
  [LETTER_TEMPLATE_TYPE.TOTAL_REFUSAL_CLINICAL_IMPERATIVE]: {
    COMPONENT: TotalRefusalClinicalImperative,
    title: 'Decisão da Junta Médica',
  },
  [LETTER_TEMPLATE_TYPE.TOTAL_REFUSAL]: {
    COMPONENT: TotalRefusal,
    title: 'Decisão da Junta Médica',
  },
  [LETTER_TEMPLATE_TYPE.REIMBURSEMENT_TOTAL_REFUSAL_EXAMS]: {
    COMPONENT: ReimbursementTotalRefusalExams,
    title: 'Decisão da Junta Médica',
  },
  [LETTER_TEMPLATE_TYPE.REIMBURSEMENT_TOTAL_REFUSAL_PROCEDURES]: {
    COMPONENT: ReimbursementTotalRefusalProcedures,
    title: 'Decisão da Junta Médica',
  },
  [LETTER_TEMPLATE_TYPE.REIMBURSEMENT_PARTIAL_VALIDATION_EXAMS]: {
    COMPONENT: ReimbursementPartialValidationExams,
    title: 'Decisão da Junta Médica',
  },
  [LETTER_TEMPLATE_TYPE.REIMBURSEMENT_PARTIAL_VALIDATION_PROCEDURES]: {
    COMPONENT: ReimbursementPartialValidationProcedures,
    title: 'Decisão da Junta Médica',
  },
  [LETTER_TEMPLATE_TYPE.PARTIAL_VALIDATION]: {
    COMPONENT: PartialValidation,
    title: 'Decisão da Junta Médica',
  },
  [LETTER_TEMPLATE_TYPE.PARTIAL_VALIDATION_CLINICAL_IMPERATIVE]: {
    COMPONENT: PartialValidationClinicalImperative,
    title: 'Decisão da Junta Médica',
  },
  [LETTER_TEMPLATE_TYPE.HEALTH_MEI_CEI_CAPEF_RN593]: {
    COMPONENT: HealthMeiCeiCapefRN593,
    title: null,
  },
  [LETTER_TEMPLATE_TYPE.HEALTH_LAST_NOTICE_INDIVIDUAL_RN593]: {
    COMPONENT: HealthLastNoticeIndividualRN593,
    title: null,
  },
  [LETTER_TEMPLATE_TYPE.ODONTO_LAST_NOTICE_INDIVIDUAL_RN593]: {
    COMPONENT: OdontoLastNoticeIndividualRN593,
    title: null,
  },
  [LETTER_TEMPLATE_TYPE.PRC_LAST_NOTICE_RN593]: {
    COMPONENT: PRCLastNoticeRN593,
    title: null,
  },
  [LETTER_TEMPLATE_TYPE.ODONTO_LAST_NOTICE_LEGAL_ENTITY_RN593]: {
    COMPONENT: OdontoLastNoticeLegalEntityRN593,
    title: null,
  },
}

const THEME_MAPPING = {
  [LETTER_TEMPLATE_TYPE.PRC_LAST_NOTICE_RN593]: themes.prc,
  [LETTER_TEMPLATE_TYPE.HEALTH_MEI_CEI_CAPEF_RN593]: themes.sula,
  [LETTER_TEMPLATE_TYPE.HEALTH_LAST_NOTICE_INDIVIDUAL_RN593]: themes.sula,
  [LETTER_TEMPLATE_TYPE.ODONTO_LAST_NOTICE_INDIVIDUAL_RN593]: themes.sula,
  [LETTER_TEMPLATE_TYPE.ODONTO_LAST_NOTICE_LEGAL_ENTITY_RN593]: themes.sula,
}

const Page = () => {
  const [letter, setLetter] = React.useState(null)
  const [error, setError] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const { changeTheme } = useCustomTheme()

  const { key } = useParams()
  const navigate = useNavigate()
  useTitle('Letter')

  const LetterComponent = letter
    ? letterTemplateMapping[letter.template_type]
    : null
  const fetchLetter = useCallback(async () => {
    setLoading(true)
    setLetter(null)
    setError(null)

    try {
      const response = await getLetter(key)
      const { data } = response
      setLetter(data)
    } catch (e) {
      if (!e.response) {
        navigate(paths.serverError)
        return
      }

      switch (e.response.status) {
        case 400:
        case 422:
          setError(e.response.data.detail)
          break
        case 404:
          navigate(paths.notFound)
          break
        case 500:
          navigate(paths.serverError)
          break
        case 401:
          navigate(paths.unauthorized)
          break
        default:
          throw e
      }
    }

    setLoading(false)
  }, [key])

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    fetchLetter()
  }, [fetchLetter])

  useEffect(() => {
    if (!letter) {
      return
    }

    changeTheme(THEME_MAPPING[letter.template_type])
  }, [changeTheme, letter])

  // noinspection JSXUnresolvedComponent
  return (
    <LetterConfirmationParamsProvider>
      <CompactLayout>
        {letter ? (
          <Container
            sx={{
              padding: {
                xs: 0,
                sm: 0,
                md: 1,
              },
            }}
          >
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                py: 0,
              }}
            >
              <Container sx={{ textAlign: 'left' }} maxWidth="xl">
                <Stack
                  spacing={{
                    xs: 3,
                    lg: 4,
                  }}
                  alignItems="center"
                  justifyContent="center"
                >
                  {LetterComponent.title && (
                    <Typography
                      variant="h4"
                      mb={4}
                      textAlign="left"
                      sx={{ width: '100%' }}
                    >
                      {LetterComponent.title}
                    </Typography>
                  )}

                  {LetterComponent && (
                    <LetterComponent.COMPONENT
                      letter={letter}
                      setLetter={setLetter}
                      loading={loading}
                      setLoading={setLoading}
                    />
                  )}
                </Stack>
              </Container>
            </Box>
          </Container>
        ) : null}

        {loading && <SplashScreen />}

        {error && (
          <ServerError
            imgSrc="/assets/errors/error-400.svg"
            imgAlt="failure"
            title="Falha no carregamento"
            description={error}
            redirectText="Voltar ao inicio"
            redirectTo={paths.index}
          />
        )}
      </CompactLayout>
    </LetterConfirmationParamsProvider>
  )
}

export default Page
