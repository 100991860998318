import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

const ServerError = ({
  title,
  description,
  imgSrc,
  imgAlt,
  redirectTo,
  redirectText,
}) => {
  const mdUp = useMediaQuery((theme) => theme.breakpoints.down('md'))

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        py: mdUp ? 8 : 4,
      }}
    >
      <Box
        alt={imgAlt}
        component="img"
        src={imgSrc}
        sx={{
          height: '40dvh',
          width: '80dvw',
          mb: 4,
        }}
      />
      <Typography align="center" variant={mdUp ? 'h1' : 'h4'}>
        {title}
      </Typography>
      <Typography align="center" color="text.secondary" sx={{ mt: 2 }}>
        {description}
      </Typography>
      {redirectTo && redirectText && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 4,
          }}
        >
          <Button component={Link} to={redirectTo} variant="contained">
            {redirectText}
          </Button>
        </Box>
      )}
    </Box>
  )
}

ServerError.defaultProps = {
  redirectTo: '..',
  redirectText: 'Voltar',
}

ServerError.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
  redirectTo: PropTypes.string,
  redirectText: PropTypes.string,
}

export default ServerError
