import { useEffect } from 'react'

import { app_name } from '../config'

const useTitle = (title = '') => {
  useEffect(() => {
    // eslint-disable-next-line
    document.title = title ? `${title} | ${app_name}` : app_name
  }, [title])
}

export default useTitle
