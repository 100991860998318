import useMediaQuery from '@mui/material/useMediaQuery'
import React from 'react'

import PropertyList from 'components/property-list'
import PropertyListItem from 'components/property-list-item'
import PropTypes from 'prop-types'

const ProcedureRequestSummary = ({ procedure }) => {

  const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const align = mdUp ? 'horizontal' : 'vertical'

  return (
    <PropertyList>
      <PropertyListItem
        align={align}
        label="Descrição do Procedimento:"
        value={`${procedure.descricao || '-'} `}
      />

      <PropertyListItem
        align={align}
        label="Quantidade solicitada:"
        value={`${procedure.quantidade_solicitada}` || '-'}
      />

      {procedure.quantidade_autorizada !== null && (
        <PropertyListItem
          align={align}
          label="Quantidade autorizada:"
          value={`${procedure.quantidade_autorizada}` || '-'}
        />
      )}

      {procedure.justificativa !== null && (
        <PropertyListItem
          align={align}
          label="Justificativa:"
          value={procedure.justificativa || '-'}
        />
      )}
    </PropertyList>
  )
}

ProcedureRequestSummary.propTypes = {
  procedure: PropTypes.shape({
    descricao: PropTypes.string.isRequired,
    quantidade_solicitada: PropTypes.number.isRequired,
    quantidade_autorizada: PropTypes.number,
    justificativa: PropTypes.string,
  }).isRequired,
}

export default ProcedureRequestSummary
