import React from 'react'
import LetterTypography from 'components/letter-typography'
import PropTypes from 'prop-types'

const CustomerServiceContact = ({ phones, type = 'installment' }) => (
  <LetterTypography>
    Caso tenha dúvidas ou o(s) pagamento(s) tenha(m) ocorrido antes do
    recebimento desta carta, solicitamos que, com o(s) comprovante(s) em mãos,
    entre em contato com a nossa Central de Atendimento (
    {phones
      .map((c, index) => {
        // Check if additional_info exists and is not 'null'
        const hasAdditionalInfo =
          c.additional_info && c.additional_info.trim().toLowerCase() !== 'null'

        // Build the phone string with or without additional_info
        const phoneString = hasAdditionalInfo
          ? `${c.number} - ${c.additional_info}`
          : c.number

        if (index === 0) {
          // For the first phone number, return the phoneString as is
          return phoneString
        }
        // For the following phone numbers, include c.description before the phone number
        const hasDescription =
          c.description && c.description.trim().toLowerCase() !== 'null'

        // Build the description string if it exists
        const descriptionString = hasDescription ? `${c.description} ` : ''

        // Return the combined string
        return `${descriptionString}${phoneString}`
      })
      .join(' ou ')}
    ) para o procedimento de baixa da(s){' '}
    {type === 'installment' ? 'parcela' : 'fatura'}(s) pendente(s) em nossos
    sistemas.
  </LetterTypography>
)

CustomerServiceContact.propTypes = {
  phones: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.string.isRequired,
      description: PropTypes.string,
      additional_info: PropTypes.string,
    }),
  ).isRequired,
  type: PropTypes.oneOf(['installment', 'invoice']),
}

CustomerServiceContact.defaultProps = {
  type: 'installment',
}

export default CustomerServiceContact
