import React from 'react'
import LetterTypography from '../../components/letter-typography'
import MaterialRequestSummary from './material_request_summary'

const MaterialListSummary = ({ materials }) => (
  <React.Fragment>
    {
      !materials?.length && <LetterTypography>-</LetterTypography>
    }

    {
      materials?.map((material) => (
        <MaterialRequestSummary
          key={material.descricao}
          material={material}
        />
      ))
    }
  </React.Fragment>
)

export default MaterialListSummary
