import React, { useCallback, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Alert, AlertTitle, Card, CardContent, TextField } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { useNavigate, useSearchParams } from 'react-router-dom'
import useTitle from '../hooks/use-title'
import CompactLayout from '../layouts/compact-layout'
import { FileDropzone } from '../components/file-dropzone'
import { validateReport } from '../integrations/api'
import paths from '../paths'
import SplashScreen from '../components/splash_screen'


const objectIdRegex = /^[0-9a-fA-F]{24}$/


const validationSchema = Yup.object({
  flowId: Yup.string()
    .required('Campo obrigatório')
    .test('is-objectId', 'O valor deve ser um ObjectId válido', value => objectIdRegex.test(value)),
})

const ReporteIntegrityCheck = () => {
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const [searchParams, setSearchParams] = useSearchParams()
  const [validationResult, setValidationResult] = useState(null)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  useTitle('Validação de Documentos')

  const initialValues = {
    flowId: searchParams.get('flowId') || '',
    files: [],
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers) => {
      setLoading(true)
      setValidationResult(null)

      try {
        const {
          flowId,
          files,
        } = values

        const response = await validateReport(flowId, files[0])

        setValidationResult(response.data)
      } catch (err) {
        if (!(err.response && err.response.data && Array.isArray(err.response.data.detail))) {
          navigate(paths.serverError)
        } else {
          err.response.data.detail.forEach((errorDetail) => {
            if (errorDetail.loc.includes('flow_id')) {
              helpers.setFieldError('flowId', errorDetail.msg)
            }
          })
        }

      } finally {
        setLoading(false)
      }
    },
  })

  const handleFilesDrop = useCallback((newFiles) => {
    formik.setFieldValue('files', newFiles)
  }, [formik])

  const handleFileRemove = useCallback(() => {
    formik.setFieldValue('files', [])
  }, [formik])

  const handleFilesRemoveAll = useCallback(() => {
    formik.setFieldValue('files', [])
  }, [formik])

  useEffect(() => {
    if (formik.values.flowId !== initialValues.flowId) {
      setSearchParams(new URLSearchParams({ flowId: formik.values.flowId }))
    }
  }, [formik.values.flowId, setSearchParams, initialValues.flowId])

  return (
    <CompactLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: mdUp ? 2 : 0,
          px: 0,
          m: 0,
        }}
      >
        <Container
          sx={{ textAlign: 'left' }}
          maxWidth="xl">
          <Typography variant={mdUp ? 'h4' : 'h5'} sx={{ mb: 4 }}>
            Validação de relatório
          </Typography>

          {loading && <SplashScreen />}

          {validationResult !== null && (
            <Alert severity={
              validationResult.is_valid ? 'success' : 'error'}
                   onClose={() => {
                     setValidationResult(null)
                     formik.resetForm()
                   }}
            >
              <AlertTitle>
                {validationResult.is_valid ? 'Documento válido' : 'Documento inválido'}
              </AlertTitle>
              {validationResult.is_valid ?
                (
                  <span>
                     Verificamos o documento enviado e constatamos que ele corresponde corretamente ao identificador
                    único {formik.values.flowId}. O documento é <b>legítimo</b>!
                  </span>
                )
                : (
                  <span>
                    Verificamos o documento enviado e constatamos que ele não corresponde ao identificador único
                    {' '} {formik.values.flowId}.
                    O documento é <b>ilegítimo</b> ou pertence a outro identificador único! Para saber a qual 
                    identificador único o documento pertence, verifique o carimbo na sua última página.
                  </span>
                )}
            </Alert>
          )}

          {!loading && !validationResult && (
            <Grid container spacing={4}>
              <Grid item md={12} xs={12}>
                <Alert severity="info">
                  O identificador único pode ser encontrado no carimbo contido na última página do documento.
                </Alert>
              </Grid>

              <Grid item md={12} xs={12}>
                <Card>
                  <CardContent
                  >
                    <Stack spacing={3}>
                      <Typography variant="h6" mt={0}>Detalhes</Typography>
                      <TextField
                        error={!!(formik.touched.flowId && formik.errors.flowId)}
                        fullWidth
                        helperText={formik.touched.flowId && formik.errors.flowId}
                        label="Identificador único"
                        name="flowId"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        maxLength={24}
                        value={formik.values.flowId}
                      />
                      <FileDropzone
                        accept={{
                          'application/pdf': [],
                          'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
                          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
                          'text/csv': [],
                          'image/png': [],
                          'image/jpeg': [],
                        }}
                        caption="PDF, DOCX, XLSX, CSV, PNG, JPG, (tamanho maximo de 10 Mb)"
                        files={formik.values.files}
                        onDrop={handleFilesDrop}
                        onRemove={handleFileRemove}
                        maxFiles={1}
                        maxSize={10485760}
                        onRemoveAll={handleFilesRemoveAll}
                        onUpload={formik.submitForm}
                      />
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
        </Container>
      </Box>
    </CompactLayout>
  )
}

export default ReporteIntegrityCheck
