import Box from '@mui/material/Box'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import PropTypes from 'prop-types'
import React from 'react'

const PropertyListItem = (props) => {
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const {
    align = 'vertical',
    children,
    disableGutters,
    value,
    label,
    ...other
  } = props

  return (
    <ListItem
      sx={{
        px: disableGutters ? 0 : 3,
        py: mdUp ? 0.2 : 0.8,
      }}
      {...other}
    >
      <ListItemText
        disableTypography
        primary={
          <Typography
            sx={{
              minWidth: align === 'vertical' ? 'inherit' : 32,
              pr: 1,
            }}
            variant="subtitle1"
          >
            {label}
          </Typography>
        }
        secondary={
          <Box
            sx={{
              flex: 1,
              mt: align === 'vertical' ? 0.5 : 0,
            }}
          >
            {children || (
              <Typography align='justify' color="text.secondary" variant="body1">
                {value || '-'}
              </Typography>
            )}
          </Box>
        }
        sx={{
          display: 'flex',
          flexDirection: align === 'vertical' ? 'column' : 'row',
          my: 0,
        }}
      />
    </ListItem>
  )
}

PropertyListItem.defaultProps = {
  align: 'horizontal',
  children: null,
  disableGutters: true,
  value: null,
}

PropertyListItem.propTypes = {
  align: PropTypes.oneOf(['horizontal', 'vertical']),
  children: PropTypes.node,
  disableGutters: PropTypes.bool,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
}

export default PropertyListItem
