import * as Sentry from '@sentry/browser'
import axios from 'axios'

axios.interceptors.response.use(
  (response) => response,
  (err) => {
    Sentry.captureException(err)
    return Promise.reject(err)
  },
)

export default axios
