import React from 'react'

import ErrorPage from './error-page'

const Page = () => (
  <ErrorPage
    seoTitle="Error: Server Error"
    imgSrc="/assets/errors/error-500.svg"
    imgAlt="Server Error"
    title="500: Erro interno"
    description="Desculpe, estamos enfrentando problemas técnicos. Por favor, tente novamente mais tarde."
    redirectTo="/"
    redirectText="Voltar ao inicio"
  />
)

export default Page
