import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Container,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import useLetterConfirmationParams from 'hooks/use-letter-confirmation-params'
import PropertyList from 'components/property-list'
import PropertyListItem from 'components/property-list-item'
import LetterTypography from 'components/letter-typography'
import { defaultLetterConfirmationParams } from 'contexts/letter-confirmation-params'
import { alpha } from '@mui/system/colorManipulator'

import {
  AnsBadge,
  BillingTable,
  CompanyInfo, CustomerServiceContact,
  PaymentNotice,
  WhatsAppCard,
} from '../components'
import PaymentGuidelines from './payment_guidelines'
import SulaPortalCard from './sula_portal'
import SulaAppCard from './sula_app_card'

const HealthLastNoticeRN593 = ({ letter }) => {
  const { params } = letter
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const align = mdUp ? 'horizontal' : 'vertical'
  const theme = useTheme()
  const { saveLetterConfirmationParams } = useLetterConfirmationParams()

  useEffect(() => {
    saveLetterConfirmationParams(defaultLetterConfirmationParams)
  }, [saveLetterConfirmationParams])

  const propertyItems = [
    {
      label: 'Sr(a).',
      value: params.recipient_name,
      mb: 5,
    },
    {
      label: 'Carteirinha: Nº',
      value: params.card_number,
    },
    {
      label: 'Produto/Plano contratado:',
      value: params.product_plan,
    },
    {
      label: 'CPF:',
      value: params.tax_id,
    },
    {
      label: 'Contrato:',
      value: params.contract,
    },
    {
      label: 'Dsc Produto ANS:',
      value: params.ans_product_description,
    },
    {
      label: 'Registro ANS:',
      value: params.ans_registration,
    },
  ]

  return (
    <Container
      sx={{
        backgroundColor: (th) => alpha(th.palette.grey[500], 0.1),
        padding: {
          xs: 0.1,
          sm: 0.5,
          md: 1,
        },
      }}
      maxWidth="sm"
    >
      <Stack
        textAlign="justify"
        direction="column"
        spacing={3}
        sx={{
          backgroundColor: theme.palette.background.default,
        }}
      >
        <img
          src="https://www.sulamerica.com.br/emkt/saude/3399-header.png"
          alt="Cabeçalho da SulAmérica"
        />

        <Stack
          justifyContent="space-between"
          textAlign="justify"
          spacing={3}
          sx={{
            backgroundColor: theme.palette.background.default,
            padding: {
              xs: 1.5,
              md: 3,
              lg: 4,
            },
          }}
        >
          <PropertyList>
            {propertyItems.map((item) => (
              <React.Fragment key={`${item.label}-${item.value}`}>
                <PropertyListItem
                  align={align}
                  label={item.label}
                  value={item.value}
                  mb={item.mb}
                />
                {item.mb && <br />}
              </React.Fragment>
            ))}
          </PropertyList>

          <PaymentNotice theme={theme} />

          <Typography variant="h6">Olá,</Typography>

          <LetterTypography>
            Informamos que, até o presente momento, não foi identificado o
            pagamento referente às parcelas indicadas abaixo:
          </LetterTypography>

          <BillingTable
            billingItems={letter.params.billing_items}
            type="invoice"
          />

          <LetterTypography>
            *Obs: Os valores supracitados poderão sofrer alterações, devido à
            incidência de juros e multa calculados da data de emissão dessa
            comunicação até a data do efetivo pagamento.
          </LetterTypography>

          <LetterTypography>
            <b>
              Importante ressaltar que o não pagamento das mensalidades em até
              10 dias, a contar do recebimento deste aviso, implicará no
              cancelamento automático do seu Contrato de Seguro Saúde, com a
              exclusão de todos os beneficiários a ele vinculados, conforme
              disposto na legislação vigente e/ou nas Condições Gerais do plano
              contratado, sem prejuízo da cobrança por essa Seguradora das
              parcelas vencidas e não pagas.
            </b>
          </LetterTypography>

          <LetterTypography>
            Para sua comodidade, destacamos que a segunda via dos boletos
            referente às parcelas poderão ser solicitadas por algum dos canais
            abaixo:
          </LetterTypography>

          <SulaPortalCard
            websites={params.duplicate_invoice_request_contact.websites}
          />

          <SulaAppCard />

          <WhatsAppCard
            title="WhatsApp oficial SulAmérica"
            phone={params.duplicate_invoice_request_contact.whatsapp}
          />

          <Typography variant="body1" fontWeight="bold" textAlign="center">
            Ao efetuar o pagamento, é de suma importância verificar:
          </Typography>

          <PaymentGuidelines
            company="SulAmérica"
            email={params.official_contact_email}
          />

          <CustomerServiceContact phones={params.customer_service.phones} type="invoice"/>
        </Stack>

        <img
          src="https://www.sulamerica.com.br/emkt/saude/4711-footer-v11-24.png"
          alt="Rodapé da SulAmérica"
        />

        <Stack
          justifyContent="space-between"
          textAlign="justify"
          spacing={3}
          sx={{
            backgroundColor: theme.palette.background.default,
            padding: {
              xs: 4,
              lg: 4,
            },
          }}
        >
          <AnsBadge registrationNumber={letter.params.ans_registration} />

          <CompanyInfo company_info={letter.params.basic_company_info} />
        </Stack>
      </Stack>
    </Container>
  )
}

HealthLastNoticeRN593.propTypes = {
  letter: PropTypes.shape({
    ttl: PropTypes.number.isRequired,
    expires_at: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    template_type: PropTypes.string.isRequired,
    awareness_declared_at: PropTypes.string,
    confirmation: PropTypes.shape({
      action: PropTypes.string.isRequired,
    }),
    params: PropTypes.shape({
      recipient_name: PropTypes.string.isRequired,
      product_plan: PropTypes.string.isRequired,
      tax_id: PropTypes.string.isRequired,
      ans_product_description: PropTypes.string.isRequired,
      ans_registration: PropTypes.string.isRequired,
      card_number: PropTypes.string.isRequired,
      contract: PropTypes.string.isRequired,
      billing_items: PropTypes.arrayOf(
        PropTypes.shape({
          number: PropTypes.string.isRequired,
          due_date: PropTypes.string.isRequired,
          days_late: PropTypes.number.isRequired,
          original_value: PropTypes.number.isRequired,
          updated_value: PropTypes.number.isRequired,
        }),
      ),
      basic_company_info: PropTypes.shape({
        website: PropTypes.shape({
          url: PropTypes.string.isRequired,
          display_text: PropTypes.string.isRequired,
        }),
        name: PropTypes.string.isRequired,
        address: PropTypes.string.isRequired,
      }).isRequired,
      duplicate_invoice_request_contact: PropTypes.shape({
        whatsapp: PropTypes.string.isRequired,
        websites: PropTypes.arrayOf(
          PropTypes.shape({
            url: PropTypes.string.isRequired,
            display_text: PropTypes.string.isRequired,
            additional_info: PropTypes.string,
          }),
        ),
      }).isRequired,
      customer_service: PropTypes.shape({
        phones: PropTypes.arrayOf(
          PropTypes.shape({
            number: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            additional_info: PropTypes.string.isRequired,
          }),
        ),
      }),
      official_contact_email: PropTypes.string,
    }).isRequired,
  }).isRequired,
}

export default HealthLastNoticeRN593
