import { useScroll } from 'framer-motion'
import { useCallback, useEffect, useMemo, useState } from 'react'

const useOffSetTop = (top, options) => {
  const { scrollY } = useScroll(options)

  const [value, setValue] = useState(false)

  const onOffSetTop = useCallback(() => {
    scrollY.on('change', (scrollHeight) => {
      setValue(scrollHeight > top)
    })
  }, [scrollY, top])

  useEffect(() => {
    onOffSetTop()
  }, [onOffSetTop])

  return useMemo(() => value, [value])
}

export default useOffSetTop
