import useMediaQuery from '@mui/material/useMediaQuery'
import PropTypes from 'prop-types'
import React from 'react'
import { Paper, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { useConfirm } from 'material-ui-confirm'
import Stack from '@mui/material/Stack'
import { disagreeWithAll } from './tie-breaker-selector'

const TieBreakersSummary = (props) => {
  const { nominatedTieBreakers } = props
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const confirm = useConfirm()

  const handleClick = async (tieBreaker) => {
    confirm({
      title: `${tieBreaker.nome} - CRM/CRO ${tieBreaker.crm_cro}`,
      content: tieBreaker.curriculo,
      hideCancelButton: true,
    }).catch(() => {
    })
  }

  return (
    <Stack
      spacing={2}>
      {!nominatedTieBreakers?.length && (
        <Typography variant="subtitle2">
          -
        </Typography>
      )}
      {nominatedTieBreakers?.map((tieBreaker) => (
        <Paper
          key={tieBreaker.crm_cro}
          variant="outlined"
          sx={{
            m: 0,
            p: 1.5,
          }}
        >
          <Typography variant="subtitle2">
            {typeof tieBreaker === 'string' ? disagreeWithAll : `${tieBreaker.nome || '-'} - CRM/CRO ${tieBreaker.crm_cro || '-'}`}
          </Typography>

          <Typography
            variant="body2"
            sx={{
              mt: .5,
            }}
          >
            {typeof tieBreaker === 'object' && mdUp ? tieBreaker.curriculo || '-' : ''}
          </Typography>

          {mdUp ? null : tieBreaker.curriculo && (
            <Button
              sx={{
                m: 0,
                p: 0.5,
                ml: -.5,
              }}
              size="large"
              onClick={() => handleClick(tieBreaker)}
              variant="text"
            >
              Ver currículo
            </Button>
          )}
        </Paper>
      ))}
    </Stack>
  )
}

TieBreakersSummary.defaultProps = {
  nominatedTieBreakers: null,
}

TieBreakersSummary.propTypes = {
  nominatedTieBreakers: PropTypes.arrayOf(
    PropTypes.shape({
      nome: PropTypes.string,
      crm_cro: PropTypes.string,
      curriculo: PropTypes.string,
    }),
  ),
}

export default TieBreakersSummary
