import React from 'react'
import { Box, Divider, Stack, useMediaQuery } from '@mui/material'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material/styles'
import InstructionSection from '../components/instruction_section'

function PRCPaymentGuidelines({ company }) {
  const theme = useTheme()
  const mdUp = useMediaQuery((th) => th.breakpoints.up('md'))
  const color = theme.palette.primary.main
  const borderColor = theme.palette.text.primary

  const sections = []

  sections.push(
    {
      title: 'Proteção do boleto',
      content: (
        <React.Fragment>
          Para abrir seu boleto enviado por e-mail, a {company} sempre solicita
          a <strong>validação do titular do contrato.</strong>
        </React.Fragment>
      ),
      image: {
        src: 'https://www.sulamerica.com.br/emkt/saude/4871-protecao-icone.png',
        alt: 'Ícone de proteção',
      },
    },
    {
      title: 'Validação de dados',
      content: (
        <React.Fragment>
          No momento do pagamento, confira a razão social e o CNPJ, ou seja, se
          o boleto que você está pagando realmente é em nome da {company}.
          <strong>
            {' '}
            Faça a checagem tanto no boleto quanto na tela de confirmação de
            pagamento no banco.
          </strong>
        </React.Fragment>
      ),
      image: {
        src: 'https://www.sulamerica.com.br/emkt/saude/4871-protecao-icone.png',
        alt: 'Ícone de proteção',
      },
    },
  )

  return (
    <Stack
      py={2}
      spacing={2}
      px={mdUp ? 4 : 1.5}
      sx={{
        border: `2px solid ${borderColor}`,
        borderRadius: '40px',
      }}
    >
      {sections.map((section, index) => (
        <React.Fragment key={section.title}>
          <InstructionSection
            title={section.title}
            content={section.content}
            image={section.image}
            color={color}
            borderColor={borderColor}
          />
          {index !== sections.length - 1 && (
            <Box px={2}>
              <Divider
                sx={{
                  borderColor: borderColor,
                }}
              />
            </Box>
          )}
        </React.Fragment>
      ))}
    </Stack>
  )
}

PRCPaymentGuidelines.propTypes = {
  company: PropTypes.string.isRequired,
}

export default PRCPaymentGuidelines
