import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import PropertyList from 'components/property-list'
import PropertyListItem from 'components/property-list-item'
import useLetterConfirmationParams from 'hooks/use-letter-confirmation-params'
import LetterTypography from 'components/letter-typography'
import DivergenceSummary from 'sections/letter/divergence_summary'
import { defaultLetterConfirmationParams } from 'contexts/letter-confirmation-params'
import InsuredSummary from '../insured_summary'
import TieBreakersSummary from '../tie-breakers-summary'
import RequestSummary from '../request_summary'
import VideoHyperlink from '../video-hyperlink'


const ConsensusToInsured = ({ letter }) => {
  const { params } = letter
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const align = mdUp ? 'horizontal' : 'vertical'

  const { saveLetterConfirmationParams } = useLetterConfirmationParams()

  useEffect(() => {
    saveLetterConfirmationParams(defaultLetterConfirmationParams)
  }, [])
  
  return (
    <Stack
      textAlign="left"
      direction="column"
      justifyContent="space-between"
      spacing={4}
    >
      <Stack>
        <InsuredSummary name={params.nome_segurado} code={params.identificacao_segurado} />
        <PropertyList>
          <PropertyListItem
            align={align}
            label="Protocolo da solicitação:"
            value={params.numero_protocolo}
          />

          <PropertyListItem
            align={align}
            label="Profissional Solicitante Dr(a).:"
            value={params.nome_profissional_solicitante}
          />

          <PropertyListItem
            align={align}
            label="Profissional da Operadora (2ª Opinião) Dr(a):"
            value={params.nome_perito}
          />
          
          <PropertyListItem
            align={align}
            label="CRM/CRO:"
            value={params.CRMCRO_perito}
          />
        </PropertyList>
      </Stack>

      <LetterTypography>
        A SulAmérica, após a análise dos quesitos enviados para o (a) Dr. (a){' '}
        {params.nome_perito} CRM/CRO{' '}{params.CRMCRO_perito}, indicado por esta
        operadora para realização de segunda opinião, identificou divergências
        em relação aos seguintes procedimentos:
      </LetterTypography>
      
      <RequestSummary
        materials={params.materiais}
        procedures={params.procedimentos}
        displayText={true}
      />

      <DivergenceSummary divergences={params.divergencia_solicitacao}/>

      <LetterTypography>
        Assim, em continuidade aos trâmites referentes à realização de Junta
        Médica, nos do Art. 2º, inciso II da Resolução Normativa n° 424 de
        26/06/17, da Agência Nacional de Saúde Suplementar – ANS, bem como ao
        disposto no art. 10, inc. III, foi solicitado ao seu profissional
        assistente, a indicação de profissional desempatador segundo das opções
        fornecidas pela SulAmérica, a saber:
      </LetterTypography>

      <TieBreakersSummary
        nominatedTieBreakers={params.desempatadores}
      />

      <LetterTypography>
        O seu profissional assistente tem o prazo de 2 (dois) dias úteis,
        contados da data do recebimento da proposta de consenso para se
        manifestar. Assim que o profissional retornar com o seu posicionamento,
        será feito um novo contato informando os detalhes acerca dos próximos
        passos, assim como informações referentes a identificação do
        profissional desempatador escolhido.
      </LetterTypography>

      <LetterTypography>
        Cabe destacar que será necessário disponibilizar todos os documentos e
        exames realizados que fundamentaram a solicitação do procedimento.
      </LetterTypography>

      <LetterTypography>
        Em caso de recusa, intempestividade ou silêncio por parte do seu
        profissional assistente em relação aos profissionais indicados como
        desempatadores, caberá a operadora indicar o profissional desempatador
        entre os quatro sugeridos conforme disposto no Art. 11, § 2ºda RN n° 424
        de 26/06/17.
      </LetterTypography>

      <LetterTypography>
        {' '}
        A junta poderá ser realizada nas modalidades presencial ou à distância,
        definida a critério do desempatador, nos termos do art. 13 da referida
        resolução normativa.
      </LetterTypography>

      <LetterTypography>
        {' '}
        A ausência não comunicada pelo beneficiário em caso de junta presencial,
        desobrigará a operadora a cobrir o procedimento solicitado, sendo
        facultado ao beneficiário reiniciar o procedimento de autorização,
        solicitando-o novamente, conforme disposto no Art. 10º, inciso VII e
        art. 16 referida resolução normativa.
      </LetterTypography>

      <Typography color="text.primary" variant="subtitle2">
        ATENÇÃO:
      </Typography>

      <Stack
        textAlign="left"
        direction="column"
        justifyContent="space-between"
        spacing={1}
      >
        <LetterTypography>
          A SulAmérica não se responsabiliza por procedimentos realizados sem
          Validação Prévia.
          <br />
          <br />
          Para mais informações, entre em contato com a nossa Central de
          Relacionamento Cliente Saúde, no número que consta no verso do seu
          cartão de identificação.
          <br />
          <br />
          Atenciosamente,
          <br />
          <br />
          {' '}
          SulAmérica Seguro Saúde
        </LetterTypography>
      </Stack>
      
      <VideoHyperlink/>
    </Stack>
  )
}

ConsensusToInsured.propTypes = {
  letter: PropTypes.shape({
    ttl: PropTypes.number.isRequired,
    expires_at: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    template_type: PropTypes.string.isRequired,
    awareness_declared_at: PropTypes.string,
    confirmation: PropTypes.shape({
      action: PropTypes.string.isRequired,
    }),
    params: PropTypes.shape({
      params_name: PropTypes.string,
      nome_segurado: PropTypes.string,
      identificacao_segurado: PropTypes.string,
      nome_profissional_solicitante: PropTypes.string,
      nome_perito: PropTypes.string,
      CRMCRO_perito: PropTypes.string,
      divergencia_solicitacao: PropTypes.string,
      numero_protocolo: PropTypes.string,
      desempatadores: PropTypes.arrayOf(
        PropTypes.shape({
          nome: PropTypes.string,
          crm_cro: PropTypes.string,
          curriculo: PropTypes.string,
        }),
      ),
      materiais: PropTypes.arrayOf(
        PropTypes.shape({
          descricao: PropTypes.string,
          quantidade_solicitada: PropTypes.number,
          status: PropTypes.string,
        }),
      ),
      procedimentos: PropTypes.arrayOf(
        PropTypes.shape({
          descricao: PropTypes.string,
          quantidade_solicitada: PropTypes.number,
          codigo_procedimento: PropTypes.string,
          status: PropTypes.string,
        }),
      ),
    }).isRequired,
  }).isRequired,
}

export default ConsensusToInsured
