import React from 'react'
import LetterTypography from 'components/letter-typography'
import ProcedureRequestSummary from './procedure_request_summary'

const ProcedureListSummary = ({ procedures }) => (
    <React.Fragment>
      {
        !procedures?.length && <LetterTypography>-</LetterTypography>
      }

      {
        procedures?.map((procedure) => (
          <ProcedureRequestSummary
            key={procedure.descricao}
            procedure={procedure}
          />
        ))
      }
    </React.Fragment>
  )

export default ProcedureListSummary
