import React from 'react'

import ErrorPage from './error-page'

const Page = () => (
  <ErrorPage
    seoTitle="Error: Unauthorized"
    imgSrc="/assets/errors/error-401.svg"
    imgAlt="Unauthorized"
    title="401: Não autorizado"
    description="Desculpe, mas parece que você não tem permissão para acessar esta página."
    redirectTo="/"
    redirectText="Voltar ao inicio"
  />
)

export default Page
