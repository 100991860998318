import React from 'react'
import { Box, Typography } from '@mui/material'
import PropTypes from 'prop-types'

function AnsBadge({ registrationNumber }) {
  return (
    <Box
      sx={{
        display: 'inline-flex',
        padding: '2px',
        backgroundColor: '#fff',
        border: '2px solid black',
        alignSelf: 'flex-start',
      }}
    >
      <Box
        sx={{
          display: 'inline-flex',
          backgroundColor: '#000',
          color: '#fff',
          padding: '2px 6px',
        }}
      >
        <Typography
          variant="caption"
          component="span"
          sx={{ fontWeight: 'bold' }}
        >
          ANS - n.º {registrationNumber}
        </Typography>
      </Box>
    </Box>
  )
}

AnsBadge.propTypes = {
  registrationNumber: PropTypes.string.isRequired,
}

export default AnsBadge
