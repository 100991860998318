export const LETTER_TEMPLATE_TYPE = {
  CONSENSUS_TO_INSURED: 'consenso_ao_segurado',
  DENIED_CONSENSUS_PROPOSAL_TO_APPLICANT:
    'proposta_de_consenso_ao_solicitante_negado',
  PARTIAL_AUTHORIZATION_CONSENSUS_PROPOSAL_TO_APPLICANT:
    'proposta_de_consenso_ao_solicitante_autorizado_parcial',
  TOTAL_REFUSAL_NON_ATTENDANCE_DEADLOCK:
    'nao_comparecimento_recusa_total_desempate',
  TOTAL_REFUSAL_NON_ATTENDANCE_EXAMS: 'nao_comparecimento_recusa_total_exames',
  PARTIAL_VALIDATION_NON_ATTENDANCE_DEADLOCK:
    'nao_comparecimento_validacao_parcial_desempate',
  PARTIAL_VALIDATION_NON_ATTENDANCE_EXAMS:
    'nao_comparecimento_validacao_parcial_exames',
  TOTAL_REFUSAL_DENTAL_EXTRACTION: 'recusa_total_extracao_dentaria',
  TOTAL_REFUSAL_CLINICAL_IMPERATIVE: 'recusa_total_imperativo_clinico',
  TOTAL_REFUSAL: 'recusa_total',
  REIMBURSEMENT_TOTAL_REFUSAL_EXAMS: 'reembolso_recusa_total_exames',
  REIMBURSEMENT_TOTAL_REFUSAL_PROCEDURES:
    'reembolso_recusa_total_procedimentos',
  REIMBURSEMENT_PARTIAL_VALIDATION_EXAMS: 'reembolso_validacao_parcial_exames',
  REIMBURSEMENT_PARTIAL_VALIDATION_PROCEDURES:
    'reembolso_validacao_parcial_procedimentos',
  PARTIAL_VALIDATION_CLINICAL_IMPERATIVE:
    'validacao_parcial_imperativo_clinico',
  PARTIAL_VALIDATION: 'validacao_parcial',
  TOTAL_VALIDATION: 'validacao_total',
  // RN593
  HEALTH_MEI_CEI_CAPEF_RN593: 'saude_mei_cei_capef_rn593',
  HEALTH_LAST_NOTICE_INDIVIDUAL_RN593: 'saude_ultimo_aviso_individual_rn593',
  ODONTO_LAST_NOTICE_INDIVIDUAL_RN593: 'odonto_ultimo_aviso_individual_rn593',
  PRC_LAST_NOTICE_RN593: 'prc_ultimo_aviso_rn593',
  ODONTO_LAST_NOTICE_LEGAL_ENTITY_RN593: 'odonto_ultimo_aviso_pessoa_juridica_rn593',
}


export const ERROR_MESSAGES = {
  INTERNAL_SERVER_ERROR: 'Desculpe, estamos com problemas técnicos no momento.' +
    ' Por favor, tente novamente mais tarde. Se o problema persistir, entre em' +
    ' contato com o suporte',
}

export const LETTER_STATUS = {
  CREATED:'CREATED',
  READ:'READ',
  CONFIRMED:'CONFIRMED',
  EXPIRED:'EXPIRED',
}
