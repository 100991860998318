import { useContext } from 'react'
import { LetterConfirmationParamsContext } from '../contexts/letter-confirmation-params/letter-confirmation-params-context' // Modificado para importar o contexto

const useLetterConfirmationParams = () => {
  const context = useContext(LetterConfirmationParamsContext) 

  if (context === undefined) {
    throw new Error('useLetterConfirmationParams must be used within a LetterConfirmationParamsProvider')
  }

  return context
}

export default useLetterConfirmationParams
