import { CORE_API_CONFIG } from '../config'

import axios from './axiosBase'

const baseUrl = CORE_API_CONFIG.API_BASE_URL


/**
 * General service function for making API requests.
 *
 * @param method {String} - The HTTP method for the API request (e.g., 'GET', 'POST').
 * @param endpoint {String} - The endpoint (URL) for the API request.
 * @param data {Object} - Optional Data to be sent in the body of the request (for methods like POST or PUT).
 * @param params {Object} - Optional parameters to include in the query for the request.
 * @param contentType {String} - The content type of the request.
 *
 * @returns {Promise} - Resolves to the response of the API call.
 */
const service = async (method, endpoint, data = null, params = null, contentType = 'application/json') => {

  const headers = {
    'Content-Type': contentType,
    Accept: 'application/json',
    'X-API-Key': CORE_API_CONFIG.X_API_KEY,
  }

  const requestConfig = {
    method,
    url: `${baseUrl}${endpoint}`,
    headers: headers,
    crossDomain: true,
    data,
    params,

  }

  return axios(requestConfig)
}

/**
 * Make a GET request.
 *
 * @param payload {Object} - Should include:
 *    endpoint {string} - The endpoint (URL) for the GET request.
 *    params {Object} - Optional parameters to include in the query.
 * @returns {Promise} - Resolves to the response of the API call.
 */
export const get = (payload) =>
  service('GET', payload.endpoint, null, payload.params)

/**
 * Make a POST request.
 *
 * @param payload {Object} - Should include:
 *    endpoint {string} - The endpoint (URL) for the POST request.
 *    data {Object} - Data to be sent in the POST request.
 * @returns {Promise} - Resolves to the response of the API call.
 */
export const post = (payload) => service('POST', payload.endpoint, payload.data)

/**
 * Make a POST request.
 *
 * @param payload {Object} - Should include:
 *    endpoint {string} - The endpoint (URL) for the POST request.
 *    data {Object} - Data to be sent in the POST request.
 * @returns {Promise} - Resolves to the response of the API call.
 */
export const patch = (payload) => service('PATCH', payload.endpoint, payload.data)

/**
 * Get the letter based on a signed url token, returns a promise
 *
 * @param {string} letterToken The signed url token for the letter
 * @returns Promise of the request to be manipulated by the user
 */
export const getLetter = (letterToken) =>
  get({
    endpoint: `/api/v1/letter/${letterToken}`,
  })

/**
 * Confirm the letter based on the letter id, returns a promise
 * @param letter_id
 * @param confirmationData
 * @returns {Promise}
 */
export const confirmLetter = (letter_id, confirmationData = null) =>
  patch({
    endpoint: `/api/v1/letter/${letter_id}/confirm`,
    data: confirmationData,
  })


export const validateReport = (flow_id, file) => {
  const formData = new FormData()
  formData.append('file', file)

  return service(
    'POST',
    `/api/v1/report/${flow_id}/validate`,
    formData,
    null,
    'multipart/form-data',
  )
}
