import AppBar from '@mui/material/AppBar'
import { alpha, useTheme } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import React from 'react'

import Logo from '../../components/logo'
import useOffSetTop from '../../hooks/use-off-set-top'
import { HEADER } from '../config-layout'

const bgBlur = (options, theme) => {
  const defaultOptions = {
    color: theme.palette.common.white,
    blur: 6,
    opacity: 0.8,
  }
  const { color, blur, opacity, imgUrl } = { ...defaultOptions, ...options }

  let style = {
    backdropFilter: `blur(${blur}px)`,
    WebkitBackdropFilter: `blur(${blur}px)`,
    backgroundColor: alpha(color, opacity),
  }

  if (imgUrl) {
    style = {
      position: 'relative',
      backgroundImage: `url(${imgUrl})`,
      '&:before': {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 9,
        content: '""',
        width: '100%',
        height: '100%',
        backdropFilter: `blur(${blur}px)`,
        WebkitBackdropFilter: `blur(${blur}px)`,
        backgroundColor: alpha(color, opacity),
      },
    }
  }

  return style
}

export default function HeaderSimple() {
  const theme = useTheme()

  const offsetTop = useOffSetTop(HEADER.H_DESKTOP)
  const blurStyle = bgBlur({}, theme)

  return (
    <AppBar
      sx={{
        boxShadow: offsetTop ? theme.shadows[3] : 0,
        backgroundColor: 'transparent',
      }}
    >
      <Toolbar
        sx={{
          justifyContent: 'space-between',
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          background: theme.palette.background.default,
          color: theme.palette.text.primary,
          transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(offsetTop && {
            ...blurStyle,
            height: {
              md: HEADER.H_DESKTOP_OFFSET,
              xs: HEADER.H_MOBILE_OFFSET,
            },
          }),
        }}
      >
        <Logo />
      </Toolbar>
    </AppBar>
  )
}
