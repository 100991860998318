import Box from '@mui/material/Box'
import React from 'react'

import Logo from './logo'

const SplashScreen = () => (
  <Box
    sx={{
      alignItems: 'center',
      backgroundColor: 'background.paper',
      display: 'flex',
      flexDirection: 'column',
      height: '100dvh',
      justifyContent: 'center',
      left: 0,
      p: 3,
      position: 'fixed',
      top: 0,
      width: '100dvw',
      zIndex: 1400,
    }}
  >
    <Box
      sx={{
        display: 'inline-flex',
        height: 32,
        width: 120,
      }}
    >
      <Logo />
    </Box>
  </Box>
)

export default SplashScreen
