import Stack from '@mui/material/Stack'
import useMediaQuery from '@mui/material/useMediaQuery'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

import PropertyList from 'components/property-list'
import PropertyListItem from 'components/property-list-item'
import useLetterConfirmationParams from 'hooks/use-letter-confirmation-params'
import LetterTypography from 'components/letter-typography'
import { isLetterInFinalState } from 'utils/letter'
import InsuredSummary from '../insured_summary'
import TieBreakerSelector from '../tie-breaker-selector'
import RequestSummary from '../request_summary'
import LetterConfirmationForm from '../letter-confirmation-form'
import DivergenceSummary from '../divergence_summary'
import VideoHyperlink from '../video-hyperlink'

const DeniedConsensusProposalToApplicant = ({ letter, setLetter, loading, setLoading }) => {
  const {
    params,
    confirmation,
  } = letter
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const align = mdUp ? 'horizontal' : 'vertical'
  const [selectedTieBreaker, setSelectedTieBreaker] = React.useState(
    confirmation?.tie_breaker ?? '',
  )

  const {
    saveLetterConfirmationParams,
    setValidationMessage,
  } = useLetterConfirmationParams()

  useEffect(() => {
    saveLetterConfirmationParams(
      {
        action: 'consent_denied',
        tie_breaker: selectedTieBreaker === '' ? null : selectedTieBreaker,
      },
    )

    setValidationMessage(selectedTieBreaker === '' ? 'Selecione um desempatador' : '')
  }, [selectedTieBreaker])

  return (
    <Stack
      textAlign="left"
      direction="column"
      justifyContent="space-between"
      spacing={4}
    >
      <Stack>
        <InsuredSummary name={params.nome_segurado} code={params.identificacao_segurado} />

        <PropertyList>
          <PropertyListItem
            align={align}
            label="Profissional Solicitante Dr(a).:"
            value={params.nome_profissional_solicitante}
          />

          <PropertyListItem
            align={align}
            label="Email:"
            value={params.email}
          />

          <PropertyListItem
            align={align}
            label="Profissional da Operadora (2ª Opinião) Dr(a).:"
            value={params.nome_perito}
          />

          <PropertyListItem
            align={align}
            label="CRM/CRO:"
            value={params.CRMCRO_perito}
          />
        </PropertyList>
      </Stack>

      <LetterTypography>
        A SulAmérica, após a análise dos quesitos enviados ao (a) Dr. (a) {' '}
        {params.nome_perito} CRM/CRO {params.CRMCRO_perito}, indicado
        por esta operadora para realização de segunda opinião, identificou
        divergências em relação aos seguintes procedimentos:
      </LetterTypography>

      <RequestSummary
        materials={params.materiais}
        procedures={params.procedimentos}
        displayText={true}
      />

      <DivergenceSummary divergences={params.divergencia_solicitacao}/>

      <LetterTypography>
        Informamos que diante da (s) divergência (s) apresentada (s), será
        necessária à realização de Junta Médica para dirimir a divergência
        técnico-assistencial identificada, nos termos da Resolução Normativa nº
        424, de 2017, da Agência Nacional de Saúde Suplementar - ANS.
      </LetterTypography>

      <LetterTypography>
        Para tanto, solicitamos que indique dentre os profissionais abaixo
        listados, aquele que deverá atuar como desempatador acerca da
        divergência apresentada.
      </LetterTypography>

      <TieBreakerSelector
        disabled={isLetterInFinalState(letter)}
        nominatedTieBreakers={params.desempatadores || []}
        selectedTieBreaker={selectedTieBreaker}
        setSelectedTieBreaker={setSelectedTieBreaker}
      />

      {!isLetterInFinalState(letter) && (
        <LetterConfirmationForm
          letter_id={letter.id}
          loading={loading}
          setLoading={setLoading}
          setLetter={setLetter}
        />
      )}
      
      <LetterTypography>
        Informamos que o prazo para sua resposta é de 2 dias úteis a contar do
        recebimento desta notificação.
      </LetterTypography>

      <LetterTypography>
        Em caso de recusa, intempestividade ou silêncio em relação aos profissionais indicados para compor a junta,
        será
        eleito pela operadora um profissional dentre os quatro indicados, nos termos do art. 10, inciso V da RN no
        424
        da ANS, de 26/06/17.
      </LetterTypography>

      <LetterTypography>
        Cabe ressaltar, que todos os documentos e exames que fundamentaram a
        solicitação do procedimento, devem obrigatoriamente ser encaminhados
        para a operadora. A ausência não comunicada do beneficiário, em caso de
        junta presencial, desobriga a operadora de cobrir o procedimento
        solicitado, conforme disposto no Art. 10, inc. VII, sendo facultado ao
        beneficiário reiniciar a solicitação para o procedimento de autorização
        nos termos do art. 16 da resolução normativa mencionada.
      </LetterTypography>

      <LetterTypography>
        SulAmérica Seguro Saúde
      </LetterTypography>

      <VideoHyperlink/>
    </Stack>
  )
}

DeniedConsensusProposalToApplicant.propTypes = {
  letter: PropTypes.shape({
    id: PropTypes.string.isRequired,
    ttl: PropTypes.number.isRequired,
    expires_at: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    template_type: PropTypes.string.isRequired,
    awareness_declared_at: PropTypes.string,
    params: PropTypes.shape({
      params_name: PropTypes.string.isRequired,
      nome_segurado: PropTypes.string,
      identificacao_segurado: PropTypes.string,
      nome_profissional_solicitante: PropTypes.string,
      nome_perito: PropTypes.string,
      CRMCRO_perito: PropTypes.string,
      email: PropTypes.string,
      divergencia_solicitacao: PropTypes.string,
      confirmation: PropTypes.shape({
        disagree_with_all_options: PropTypes.bool.isRequired,
        action: PropTypes.string.isRequired,
      }),
      desempatadores: PropTypes.arrayOf(
        PropTypes.shape({
          nome: PropTypes.string,
          crm_cro: PropTypes.string,
          curriculo: PropTypes.string,
        }),
      ).isRequired,
      materiais: PropTypes.arrayOf(
        PropTypes.shape({
          descricao: PropTypes.string,
          quantidade_solicitada: PropTypes.number,
          status: PropTypes.string,
          justificativa: PropTypes.string,
        }),
      ).isRequired,
      procedimentos: PropTypes.arrayOf(
        PropTypes.shape({
          descricao: PropTypes.string,
          quantidade_solicitada: PropTypes.number,
          codigo_procedimento: PropTypes.string,
          status: PropTypes.string,
          justificativa: PropTypes.string,
        }),
      ).isRequired,
    }).isRequired,
  }).isRequired,
}

export default DeniedConsensusProposalToApplicant
