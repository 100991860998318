import React from 'react'
import { Link, Typography } from '@mui/material'
import Stack from '@mui/material/Stack'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material/styles'

const CompanyInfo = ({ company_info }) => {
  const theme = useTheme()
  const { website, name, address } = company_info
  // noinspection JSUnresolvedReference
  const linkColor = theme.palette.neutral[400]

  return (
    <Stack spacing={0}>
      <Typography variant="caption" textAlign="left">
        Para mais informações, acesse:{' '}
        <Link
          href={website.url}
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            color: linkColor,
          }}
        >
          {website.display_text}
        </Link>
      </Typography>

      <Typography variant="caption">{name}</Typography>
      <Typography variant="caption">{address}</Typography>
    </Stack>
  )
}

CompanyInfo.propTypes = {
  company_info: PropTypes.shape({
    website: PropTypes.shape({
      url: PropTypes.string.isRequired,
      display_text: PropTypes.string.isRequired,
    }),
    name: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
  }).isRequired,
}

export default CompanyInfo
