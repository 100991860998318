import { createContext } from 'react'
import themes from '../../theme/light/themes'

/**
 * ThemeContext is a context that manages and provides the current theme of the application.
 * @property {object} theme - The currently applied theme in the application.
 * @property {function} changeTheme - Function to update the application's theme.
 */
const CustomThemeContext = createContext({
  theme: themes.default, // Initial default theme value
  changeTheme: (_newTheme) => {}, // Placeholder function for changeTheme
})

export default CustomThemeContext
