import React from 'react'

import ErrorPage from './error-page'

const Page = () => (
  <ErrorPage
    seoTitle="Error: Not Found"
    imgSrc="/assets/errors/error-404.svg"
    imgAlt="Not Found"
    title="404: Não encontrada"
    description="Desculpe, a página que você está procurando não existe. Por favor, use a navegação para continuar."
    redirectTo="/"
    redirectText="Voltar ao inicio"
  />
)

export default Page
