import { FormControlLabel, Paper } from '@mui/material'
import React from 'react'
import { useConfirm } from 'material-ui-confirm'
import useMediaQuery from '@mui/material/useMediaQuery'
import PropTypes from 'prop-types'
import Checkbox from '@mui/material/Checkbox'
import ListItemButton from '@mui/material/ListItemButton'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import LetterTypography from '../../components/letter-typography'

export const disagreeWithAll = 'Não concordo com nenhuma das opções.'

const TieBreakerSelector = (props) => {
  const {
    disabled,
    nominatedTieBreakers,
    selectedTieBreaker,
    setSelectedTieBreaker,
  } = props
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const confirm = useConfirm()

  const handleClick = async (tieBreaker) => {
    confirm({
      title: `${tieBreaker.nome} - CRM/CRO ${tieBreaker.crm_cro}`,
      content: tieBreaker.curriculo,
      hideCancelButton: true,
    }).then(() => {})
  }

  const handleCheckboxChange = (event) => {
    if (selectedTieBreaker === event.target.value) {
      setSelectedTieBreaker('')
    } else {
      setSelectedTieBreaker(event.target.value)
    }
  }

  const getTieBreakerValue = (tieBreaker) => (
    `${tieBreaker.nome} - CRM/CRO ${tieBreaker.crm_cro}`
  )

  return (
    <Box>
      {nominatedTieBreakers?.length === 0 ? (
        <LetterTypography>-</LetterTypography>
      ) : [...nominatedTieBreakers, disagreeWithAll].map((tieBreaker) => (
        <Paper
          key={typeof tieBreaker === 'string' ? tieBreaker : tieBreaker.crm_cro}
          sx={{
            mb: 2,
          }}
          variant="outlined"
        >
          <ListItemButton disabled={disabled} sx={{ width: '100%' }}>
            <FormControlLabel
              disabled={disabled}
              fontSize="medium"
              control={
                <Checkbox
                  edge="start"
                  checked={selectedTieBreaker === (typeof tieBreaker === 'string' ? tieBreaker : getTieBreakerValue(tieBreaker))}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': `checkbox-list-label-${(typeof tieBreaker === 'string' ? tieBreaker : tieBreaker.crm_cro)}` }}
                  onChange={handleCheckboxChange}
                  value={typeof tieBreaker === 'string' ? tieBreaker : getTieBreakerValue(tieBreaker)}
                />
              }
              label={
                <Box>
                  <Typography variant="subtitle2">
                    {typeof tieBreaker === 'string' ? disagreeWithAll : getTieBreakerValue(tieBreaker)}
                  </Typography>

                  <Typography
                    disabled={disabled}
                    variant="body2"
                    sx={{
                      mt: .5,
                    }}
                  >
                    {typeof tieBreaker === 'object' && mdUp ? tieBreaker.curriculo || '-' : ''}
                  </Typography>

                  {typeof tieBreaker === 'string' || mdUp ? null : tieBreaker.curriculo && (
                    <Button
                      disabled={disabled}
                      sx={{
                        m: 0,
                        p: 0.5,
                        ml: -.5,
                      }}
                      size="large"
                      onClick={() => handleClick(tieBreaker)}
                      variant="text"
                    >
                      Ver currículo
                    </Button>
                  )}
                </Box>
              }
            />
          </ListItemButton>
        </Paper>
      ))}
    </Box>
  )
}

TieBreakerSelector.propTypes = {
  disabled: PropTypes.bool.isRequired,
  nominatedTieBreakers: PropTypes.arrayOf(
    PropTypes.shape({
      nome: PropTypes.string,
      crm_cro: PropTypes.string,
      curriculo: PropTypes.string,
    }),
  ).isRequired,
  selectedTieBreaker: PropTypes.string.isRequired,
  setSelectedTieBreaker: PropTypes.func.isRequired,
}

export default TieBreakerSelector
