import React, { useMemo, useState } from 'react'
import CustomThemeContext from './custom_theme_context'
import themes from '../../theme/light/themes'

/**
 * ThemeProvider is a component that supplies the theme context to the application.
 * It allows the theme to be dynamically changed and accessed throughout the component tree.
 *
 * @param {React.ReactNode} children - The child components that will have access to the theme.
 * @returns {JSX.Element} - A theme context provider wrapping the children.
 */
const CustomThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(themes.default)

  /**
   * Updates the application's theme.
   *
   * @param {Theme} newTheme - The new theme to apply to the application.
   */
  const changeTheme = (newTheme) => {
    if (newTheme) {
      setTheme(newTheme)
    }
  }

  /**
   * Context value containing the current theme and a function to change it.
   * Memorizes the value to avoid unnecessary recreations and optimize renders.
   */
  const contextValue = useMemo(
    () => ({
      theme,
      changeTheme,
    }),
    [theme],
  )

  return (
    <CustomThemeContext.Provider value={contextValue}>
      {children}
    </CustomThemeContext.Provider>
  )
}

export default CustomThemeProvider
