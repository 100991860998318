import useMediaQuery from '@mui/material/useMediaQuery'
import PropTypes from 'prop-types'
import React from 'react'

import PropertyList from 'components/property-list'
import PropertyListItem from 'components/property-list-item'

const MaterialRequestSummary = ({ material }) => {

  const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const align = mdUp ? 'horizontal' : 'vertical'

  return (
    <PropertyList>
      <PropertyListItem
        align={align}
        label="Descrição do material:"
        value={material.descricao || '-'}
      />

      <PropertyListItem
        align={align}
        label="Quantidade solicitada:"
        value={`${material.quantidade_solicitada}` || '-'}
      />

      {material.quantidade_autorizada !== null && (
        <PropertyListItem
          align={align}
          label="Quantidade autorizada:"
          value={`${material.quantidade_autorizada}`}
        />
      )}

      {material.justificativa !== null && (
        <PropertyListItem
          align={align}
          label="Justificativa:"
          value={material.justificativa || '-'}
        />
      )}
    </PropertyList>
  )
}

MaterialRequestSummary.propTypes = {
  material: PropTypes.shape({
    descricao: PropTypes.string,
    quantidade_solicitada: PropTypes.number,
    quantidade_autorizada: PropTypes.number,
    justificativa: PropTypes.string,
  }).isRequired,
}

export default MaterialRequestSummary
