import Typography from '@mui/material/Typography'
import React from 'react'

const LetterTypography = (
  {
    children,
    fontStyle = 'inherit',
  },
) => (
  <Typography color="text.secondary" align='justify' variant="body1" sx={{ fontStyle: fontStyle }}>
    {children}
  </Typography>
)

export default LetterTypography
