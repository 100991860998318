import Typography from '@mui/material/Typography'
import React from 'react'
import PropTypes from 'prop-types'
import PropertyList from '../../components/property-list'
import LetterTypography from '../../components/letter-typography'

const DivergenceSummary = ({ divergences }) => (
  <PropertyList>
    <Typography color="text.primary" variant="subtitle1" mb={0.5}>
      Motivo da divergência:
    </Typography>

    <LetterTypography>
      {divergences || '-'}
    </LetterTypography>
  </PropertyList>
)

DivergenceSummary.defaultProps = {
  divergences: null,
}

DivergenceSummary.propTypes = {
  divergences: PropTypes.string,
}

export default DivergenceSummary
