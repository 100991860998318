import React from 'react'
import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import useMediaQuery from '@mui/material/useMediaQuery'
import PropTypes from 'prop-types'
import ContactCard from '../components/contact_card'

const PRCPortalCard = ({ websites }) => {
  const theme = useTheme()
  const mdUp = useMediaQuery((th) => th.breakpoints.up('md'))
  return (
    <ContactCard
      image={{
        src: 'https://www.sulamerica.com.br/emkt/saude/4871-icone-desktop.png',
        alt: 'App SulAmérica Saúde',
      }}
    >
      <Stack>
        <Typography variant="caption" color="textPrimary">
          Site Paraná Clínicas
        </Typography>
        {websites.map((website) => (
          <Stack direction={mdUp ? 'row' : 'column'} spacing={0.2}>
            <Typography variant="caption" color="textPrimary">
              {website.description ? `${website.description}: ` : ' '}
            </Typography>
            <Typography key={website.url} variant="caption" color="textPrimary">
              <a
                href={website.url}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: theme.palette.text.primary,
                }}
              >
                {website.display_text}
              </a>
            </Typography>
          </Stack>
        ))}
      </Stack>
    </ContactCard>
  )
}

PRCPortalCard.propTypes = {
  websites: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      display_text: PropTypes.string.isRequired,
      additional_info: PropTypes.string,
    }),
  ).isRequired,
}

export default PRCPortalCard
